import React, { useState, useEffect } from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FaceGroupService } from '../service/FaceGroupService';
import { EditFaceGroup } from './EditFaceGroup';

export const EditFaceGroups = (props) => {

	const [editGroups, setEditGroups] = useState([]);

	const [mergedName, setMergedName] = useState('');

	const faceGroupService = new FaceGroupService();

	const mergeFaceGroups = async (event) => {
		//console.log(mergedName);
		let array = props.selectedFaceGroups.map((group, i) => {
			return group.id;
		});
		//console.log(array);

		let payload = {
			name: mergedName,
			ids: array
		}

		faceGroupService.mergeFaceGroups(payload).then(data => {
			//setLoadedGroup(data.entity);
			//setGroupName(data.entity.name);
			//setAllFaces(data.entity.faces);
			setMergedName('');
			props.triggerGroupListUpdate();
		});
	}

	const onMergeNameChange = (event) => {
		setMergedName(event.target.value);
	}

	useEffect(() => {
		//console.log("Get group " + findGroup);
		//console.log(props.selectedFaceGroups);
		if(props.selectedFaceGroups) {
			setEditGroups( props.selectedFaceGroups.map((group, i) => {
				//console.log(group);
				return (
					<EditFaceGroup
						triggerGroupListUpdate={props.triggerGroupListUpdate}
						faceGroup={group}
						key={i} />
				);
			}));
		} else {
			setMergedName('');
		}
	}, [props.selectedFaceGroups]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card style={{ display: props.selectedFaceGroups && props.selectedFaceGroups.length > 0 ? "block" : "none"}} >
			<div className="grid">
				{ props.selectedFaceGroups && props.selectedFaceGroups.length > 1 ?
					<div className="col-12">
						<div className="grid">
							<div className="col-4">
								<span className="p-float-label">
									<InputText name="inputtext" value={mergedName} style={{ width: "100%"}} onChange={onMergeNameChange} />
									<label htmlFor="inputtext">Face Group Name</label>
								</span>
							</div>
							<div className="col-8">
								<div className="flex justify-content-end">
									<Button label="Merge Selected FaceGroups" onClick={mergeFaceGroups} icon="pi pi-check" className="mr-2" style={{ marginBottom: "10px" }}></Button>
								</div>
							</div>
						</div>
					</div>
					: null
				}
				{editGroups}
			</div>
		</Card>
	);
}

