import { BaseAuthService } from './BaseAuthService';

export class FaceGroupService extends BaseAuthService {

	getAllFaceGroups() {
		return this.api.post(`/facegroup/find?page=0&pageSize=1000`, {}).then(res => res.data);
	}

	getFaceGroupSummary() {
		return this.api.get(`facegroup/summary`).then(res => res.data);
	}

	getFaceGroup(id) {
		return this.api.get(`/facegroup/${id}`).then(res => res.data);
	}

	deleteFaceGroup(id) {
		return this.api.delete(`/facegroup/${id}`).then(res => res.data);
	}

	saveFaceGroup(faceGroup) {
		return this.api.put(`/facegroup`, faceGroup).then(res => res.data);
	}

	createFaceGroup(faceGroup) {
		return this.api.post(`/facegroup`, faceGroup).then(res => res.data);
	}

	deleteFaceFromGroup(id) {
		return this.api.delete(`/face/${id}/deleteGroup`).then(res => res.data);
	}

	mergeFaceGroups(payload) {
		return this.api.post(`/facegroup/merge`, payload).then(res => res.data);
	}

}
