
import React from 'react';

import { confirmDialog } from 'primereact/confirmdialog';

import { Button } from 'primereact/button';
import { PhotoService } from '../../service/PhotoService';

export const DeletePhotoButton = (props) => {

	const photoService = new PhotoService();

	const rejectDelete = () => {
		console.log(props);
	}

	const deletePhoto = () => {
		//console.log(loadedGroup.faces[activeIndex].photo.id);
		photoService.deletePhoto(props.photo.id).then(data => {
			//console.log(data);
			props.setShowPhotoDialog(false);
			if(props.updateHandler) {
				props.updateHandler();
			}
		});
	}

	const confirmDeletePhoto = () => {
		confirmDialog({
			message: 'Are you sure you want to delete this photo?',
			header: 'Delete Photo',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deletePhoto,
			reject: rejectDelete
		});
	}

	return (
		<Button label="Delete Photo" onClick={confirmDeletePhoto} icon="pi pi-times" ></Button>
	);
}
