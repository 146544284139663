import React, { useRef, useState, useEffect } from 'react';

//import { useQuery } from 'react-query';
import { Toast } from 'primereact/toast';
import { PhotoService } from '../service/PhotoService';
import { MainWithFooter } from '../components/MainWithFooter';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from 'primereact/dropdown';

import { PhotoResults } from '../components/PhotoResults';

export const ModerationLabelsPage = () => {

	const toast = useRef(null);
	const [selectedModerationLabels, setSelectedModerationLabels] = useState(null);

	const [updateModerationLabels, setUpdateModerationLabels] = useState(false);

	const [moderationLabelResults, setModerationLabelResults] = useState([]);

	const [aggregationCount, setAggregationCount] = useState(25);

	const [ pagination, setPagination] = useState({ page: 0, limit: 12, totalResults: 0});

	const [ displayOptions] = useState({
		showFaces: false,
		showPhotoLabels: false,
		showPhotoModerationLabels: true,
		showPhotoTexts: false,
	});

	const [photos, setPhotos] = useState([]);

	const photoService = new PhotoService();

	const [ searchOptions] = useState({"aggregations": [ "photoModerationLabels.moderationLabel.name" ]});

	const onPaginatorChange = (event) => {
		//console.log(event);
		let _pagination = {
			...pagination,
			limit: event.rows,
			page: event.page,
			first: event.first
		};
		setPagination(_pagination);
	}

	useEffect(() => {

		//console.log(selectedLabels);

		let _searchOptions = {
			...searchOptions,
			aggregationCount: aggregationCount
		};

		//console.log(selectedLabels);

		if(selectedModerationLabels) {
			let searchFilters = {};
			for(let moderationLabel of selectedModerationLabels) {
				searchFilters[moderationLabel.moderationLabel + "Filter"] = {};
				searchFilters[moderationLabel.moderationLabel + "Filter"]["photoModerationLabels.moderationLabel.name"] = {
					queryString: moderationLabel.moderationLabel
				};
			}
			_searchOptions.searchFilters = searchFilters;
		}

		photoService.search(pagination.page, pagination.limit, _searchOptions).then(data => {

			const newSelectedModerationLabels = [];
			//console.log(data.aggregations["photoLabels.label.name"]);
			const newModerationLabelResults = [];
			//Object.keys(data.aggregations["photoLabels.label.name"]).map((label, count) => {
			if(data.aggregations) {
				for(let moderationLabel in data.aggregations["photoModerationLabels.moderationLabel.name"]) {
					//console.log(data.aggregations["photoLabels.label.name"][label]);
					//console.log(count);

					let newModerationLabel = {
						moderationLabel: moderationLabel,
						count: data.aggregations["photoModerationLabels.moderationLabel.name"][moderationLabel]
					};

					newModerationLabelResults.push(newModerationLabel);
					if(data.totalResults > 0) {
						setPhotos(data.results);
					} else {
						setPhotos([]);
					}

					if(selectedModerationLabels) {
						for(let oldModerationLabel of selectedModerationLabels) {
							if(oldModerationLabel.moderationLabel === newModerationLabel.moderationLabel) {
								newSelectedModerationLabels.push(newModerationLabel);
							}
						}
					}

				}
			}
			//console.log(newSelectedLabels);
			setSelectedModerationLabels(newSelectedModerationLabels);

			let _pagination = {
				...pagination,
				totalResults: data.totalResults
			};

			setPagination(_pagination);

			setModerationLabelResults(newModerationLabelResults);
		});
	}, [pagination.page, pagination.limit, updateModerationLabels, aggregationCount]); // eslint-disable-line react-hooks/exhaustive-deps

	const itemTemplate = (rowData) => {
		//console.log(rowData);
		return (
			<div>
				{rowData.moderationLabel} {"(" + rowData.count + ")"}
			</div>
		);
	}

	const removeSelectedModerationLabel = (e, moderationLabel) => {
		let _selectedModerationLabels = [ ...selectedModerationLabels ];
		_selectedModerationLabels = _selectedModerationLabels.filter(data => data.moderationLabel !== moderationLabel.moderationLabel);
		setSelectedModerationLabels(_selectedModerationLabels);
		setUpdateModerationLabels(!updateModerationLabels);
	}

	const showSelectedItems = (props) => {
		if(selectedModerationLabels) {
			let list = [];

			for(let moderationLabel of selectedModerationLabels) {
				list.push(
					<Button key={moderationLabel.moderationLabel} label={moderationLabel.moderationLabel} onClick={(e) => {removeSelectedModerationLabel(e, moderationLabel)}} icon="pi pi-times" className="p-button-rounded" />
				);
			}
			return list;
		}
	}


	return (
		<MainWithFooter>
			<div className="grid">
				<Toast ref={toast}></Toast>
				<div className="col-12 lg:col-4 xl:col-3">
					<Card title="Moderation Labels">
						<ListBox
							value={selectedModerationLabels}
							options={moderationLabelResults}
							onChange={(e) => {
								setSelectedModerationLabels(e.value);
								setUpdateModerationLabels(!updateModerationLabels);
							}}
							multiple
							metaKeySelection={true}
							optionLabel="label"
							itemTemplate={itemTemplate}
							style={{ width: '100%' }}
							listStyle={{ maxHeight: '200px' }}
							filter
						/>
						<Dropdown value={aggregationCount} options={[10, 20, 25, 50, 100, 250, 500, 1000, 2000, 5000, 10000]} onChange={(e) => {
							setAggregationCount(e.value);
						}} />
					</Card>
				</div>
				<div className="col-12 lg:col-8 xl:col-9">
					<div className="grid">
						{ selectedModerationLabels && selectedModerationLabels.length > 0 &&
							<PhotoResults
								title="Moderated Photos"
								showSelectedItems={showSelectedItems}
								onPaginatorChange={onPaginatorChange}
								pagination={pagination}
								displayOptions={displayOptions}
								photos={photos}
								showCheckboxes={false}
								updateHandler={() => setUpdateModerationLabels(!updateModerationLabels)}
							/>
						}
					</div>
				</div>

			</div>
		</MainWithFooter>
	);
}
