import React, { useRef, useState, useEffect } from 'react';

import { Toast } from 'primereact/toast';

import { MainWithFooter } from '../components/MainWithFooter';

import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import { FaceGroupService } from '../service/FaceGroupService';
import { EditFaceGroups } from '../components/EditFaceGroups';

//import './FaceGroupsPage.scss';

export const FaceGroupsPage = () => {

	const toast = useRef(null);

	const [faceGroups, setFaceGroups] = useState([]);

	//const [localUser, setLocalUser] = useSessionStorage("localUser", null);

	const faceGroupService = new FaceGroupService();

	const [updateGroups] = useState(true);

	const [selectedFaceGroups, setSelectedFaceGroups] = useState(null);

	const triggerGroupListUpdate = () => {
		//console.log("triggerGroupListUpdate: Get all groups");
		const abortController = new AbortController();

		faceGroupService.getFaceGroupSummary().then(data => {
			//console.log(data.entity.items);

			//setActiveIndex(0);
			//console.log(selectedFaceGroups);
			let newSelectedFaceGroups = [];
			if(selectedFaceGroups) {
				for(let i in data.entity.items) {
					//console.log(i);
					//console.log(data.entity.items[i]);
					for(let group of selectedFaceGroups) {
						if(data.entity.items[i].id === group.id) {
							//console.log("We got a match");
							newSelectedFaceGroups.push(data.entity.items[i]);
						}
					}
				}
				setSelectedFaceGroups(newSelectedFaceGroups);
			}
			setFaceGroups(data.entity.items);
			//setTotalPhotos(data.totalResults);
		});

		return () => {
			abortController.abort()
		}
	}

	useEffect(() => {
		//console.log("useEffect: Get all groups");
		triggerGroupListUpdate();
	}, [updateGroups]); // eslint-disable-line react-hooks/exhaustive-deps

	const itemTemplate = (rowData) => {
		//console.log(rowData);
		return (
			<div>
				{rowData.name} ({rowData.count})
			</div>
		);
	}

	return (
		<MainWithFooter>
			<div className="grid">
				<Toast ref={toast}></Toast>
				<div className="col-12 md:col-4 lg:col-4 xl:col-3">
					<Card title="Face Groups">
						<ListBox
							id={"id"}
							value={selectedFaceGroups}
							options={faceGroups}
							onChange={(e) => { if(e.value) setSelectedFaceGroups(e.value); }}
							multiple
							metaKeySelection={true}
							optionLabel="name"
							itemTemplate={itemTemplate}
							style={{ width: '100%' }}
							listStyle={{ maxHeight: '200px' }}
							filter
						/>
					</Card>
				</div>

				<div className="col-12 md:col-8 lg:col-8 xl:col-9">
					<EditFaceGroups
						selectedFaceGroups={selectedFaceGroups}
						triggerGroupListUpdate={triggerGroupListUpdate}
					/>
				</div>
			</div>
		</MainWithFooter>
	);

}
