import React, { useState, useEffect } from 'react';

//import { useAuth0 } from "@auth0/auth0-react";

import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import { StripeService } from '../service/StripeService';

import { ProductDescription } from '../components/ProductDescription';
import { ProductDescriptionCustom } from '../components/ProductDescriptionCustom';
import { MainWithFooter } from '../components/MainWithFooter';

export const Subscription = () => {

	const [products, setProducts] = useState(null);

	const [displayPUDialog, setDisplayPUDialog] = useState(false);

	const [billingType, setBillingType] = useState("month");

	//const { user } = useAuth0();

	const stripeService = new StripeService();

	const pricingItems = [
		{label: 'Monthly Pricing', value: 'month'},
		{label: 'Yearly Pricing', value: 'year'},
	];

	const showPUDialog = () => {
		setDisplayPUDialog(true);
	};

	const hidePUDialog = () => {
		setDisplayPUDialog(false);
	}

	useEffect(() => {

		const getUserMetadata = async () => {
			stripeService.getPrices().then(data => {
				let products = {};
				for(let price of data.entities) {
					if(products[price.productObject.name]) {
						products[price.productObject.name].prices[price.recurring.interval] = price;

						//price.productObject.prices[price.recurring.interval] = price;
					} else {
						price.productObject.prices = {};
						price.productObject.prices[price.recurring.interval] = price;
						products[price.productObject.name] = price.productObject;
					}
					//console.log(price);
				}

				//console.log(products);
				setProducts(products);
			});
		}

		getUserMetadata();

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleOnSubscribe = async (productOptions) => {
		//console.log(productOptions);

		let payload = {};
		payload.productOptions = productOptions;
		//payload.stripe_customer_id = user["https://www.facegroup.io/stripe_customer_id"];

		stripeService.getCheckoutSession(payload).then(data => {
			console.log(data.entity);
			window.location.assign(data.entity);
		});

	}

	return (
		<MainWithFooter>
			<div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
				<div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
				<div className="text-700 text-xl mb-6 text-center line-height-3">
					<Dropdown value={billingType} options={pricingItems} onChange={(e) => setBillingType(e.value)} placeholder="Select a Pricing type"/>
				</div>

				<div className="grid">

					<ProductDescription
						name="Plan 1"
						storage={100}
						processing={{month: 4500, year: 45000}}
						billingType={billingType}
						products={products}
						features={[
							"Facial Recognition",
							"Label Detection",
							"Moderation Label Detection",
							"Text Detection",
							"Face Group Synchronization App",
							"Download / Backup Photo Archive and Metadata",
							"Social Media Face Grouping Integration"
						]}
						showPUDialog={showPUDialog}
						handleOnSubscribe={handleOnSubscribe} />

					<ProductDescription
						name="Plan 2"
						storage={250}
						processing={{month: 7500, year: 75000}}
						billingType={billingType}
						products={products}
						features={[
							"Facial Recognition",
							"Label Detection",
							"Moderation Label Detection",
							"Text Detection",
							"Face Group Synchronization App",
							"Download / Backup Photo Archive and Metadata",
							"Social Media Face Grouping Integration"
						]}
						showPUDialog={showPUDialog}
						handleOnSubscribe={handleOnSubscribe} />

					<ProductDescription
						name="Plan 3"
						storage={1000}
						processing={{month: 15000, year: 150000}}
						billingType={billingType}
						products={products}
						features={[
							"Facial Recognition",
							"Label Detection",
							"Moderation Label Detection",
							"Text Detection",
							"Face Group Synchronization App",
							"Download / Backup Photo Archive and Metadata",
							"Social Media Face Grouping Integration"
						]}
						showPUDialog={showPUDialog}
						handleOnSubscribe={handleOnSubscribe} />

					<ProductDescriptionCustom
						name="Custom Plan"
						billingType={billingType}
						products={products}
						features={[
							"Facial Recognition",
							"Label Detection",
							"Moderation Label Detection",
							"Text Detection",
							"Face Group Synchronization App",
							"Download / Backup Photo Archive and Metadata",
							"Social Media Face Grouping Integration"
						]}
						showPUDialog={showPUDialog}
						handleOnSubscribe={handleOnSubscribe} />

					<Dialog header="Processing Units (PUs)" visible={displayPUDialog} style={{ width: '75vw' }} onHide={hidePUDialog}>
						<p>Processing Units are used for Facial Recognition, Label Detection, Moderation Label Detection, Test Detection, and deleting faces in your photos.</p>
						<p>Facial Recognition*: When you upload 1 photo, 1PU is used to index the faces in your photo. These faces are stored and compared to other faces
							in your photo set. Each face found in the photo is then matched against all your Face Groups. If a match is found then that face will be added to the
							matched Face Group.</p>
						<p>Label Detection*: Each uploaded photo will go through a label detection which uses 1PU. The system will try to put bounding boxes around everyday
							items that are found in the photo. These labels and bounding boxes will be stored with the photo as meta data.</p>
						<p>Text Detection*: Each uploaded photo will go through a text detection process which uses 1PU. Each photo that is uploaded will have text detection
							run on it, picking out words and text found in the picture.</p>
						<p>Face Deletions: When photos go through the Facial Recognition, if faces are found in the photo those faces are stored with the photo. If no other
							faces are found these faces might get orphaned. These faces can be deleted so they don't clutter up the Face Tagging opperations. Deleting a face
							uses 1PU. Sometimes you might find a Face Group of a person that you don't know or was a background face in your photos. If you delete a Face Group
							with 15 of the same face in it, that would use 15PU.
						</p>
						<p>*Note: Facial Recognition, Label Detection, and Text Detection can be individually disabled via the settings on the profile page.</p>
					</Dialog>

					{/*<div className="col-12 lg:col-4"></div>
					<div className="col-12 lg:col-4">
						<div className="card">
							<p>Other options:</p>
								<p>Pay as you go (PAYG)*</p>
								<p>Bring your own cloud (BYOC)*</p>
								<p>*Please contact support for more information</p>
						</div>
					</div>*/}
				</div>
			</div>
		</MainWithFooter>
	);
}
