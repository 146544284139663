//import React from 'react';

//import { useAmplifyAuth } from '../useAmplifyAuth';
import { useAuthenticator } from "@aws-amplify/ui-react";

import { EmailVerificationRequired } from '../pages/EmailVerificationRequired';

export const RequireEmailValidation = ({ children, logoutHandler }) => {

	//const { isSignedIn, user, signIn, signOut } = useAmplifyAuth();
	const { user } = useAuthenticator((context) => [context.user]);

	console.log(user);

	if(user && !user.attributes.email_verified) {
		return (<EmailVerificationRequired logoutHandler={logoutHandler} />);
	} else {
		return children;
	}

}
