import React, { useState, useEffect } from 'react';

import { Paginator } from 'primereact/paginator';

import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from "primereact/dropdown";

import { FullImageWithFaceBoxes } from '../components/FullImageWithFaceBoxes';

import { FaceService } from '../service/FaceService';
//import { PhotoService } from '../service/PhotoService';
import { FaceGroupService } from '../service/FaceGroupService';
import { FullFaceWithRotation } from '../components/FullFaceWithRotation';

import { MainWithFooter } from '../components/MainWithFooter';

//import './FaceGroupingPage.scss';

export const FaceGroupingPage = () => {

	const [totalFaces, setTotalFaces] = useState(0);
	const [pageSize, setPageSize] = useState(48);
	const [page, setPage] = useState(0);
	const [first, setFirst] = useState(0);

	const [faces, setFaces] = useState([]);
	const [faceGroups, setFaceGroups] = useState([]);

	const [submitted, setSubmitted] = useState(false);
	const [updateFaces, setUpdateFaces] = useState(true);
	const [updateGroups, setUpdateGroups] = useState(true);
	const [selectedFace, setSelectedFace] = useState(null);

	const [newFaceGroup, setNewFaceGroup] = useState("");

	const [selectedFaceGroup, setSelectedFaceGroup] = useState(null);

	const [openNewFaceGroup, setOpenNewFaceGroup] = useState(false);

	const faceService = new FaceService();
	const faceGroupService = new FaceGroupService();

	useEffect(() => {
		const abortController = new AbortController();
		faceService.getUnassociatedFaces(page, pageSize).then(data => {
			//console.log(data);
			setTotalFaces(data.totalResults);
			setFaces(data.results);
			if(selectedFace) {
				for(let i in data.results) {
					if(data.results[i].id === selectedFace.id) {
						//console.log("Selected: " + selectedFaceGroup.id);
						setSelectedFace(data.results[i]);
					}
				}
			}
		});

		return () => {
			abortController.abort()
		}
	}, [updateFaces, page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		const abortController = new AbortController();
		faceGroupService.getFaceGroupSummary().then(data => {
			//console.log(data);
			setFaceGroups(data.entity.items);
		});
		return () => {
			abortController.abort()
		}
	}, [updateGroups]); // eslint-disable-line react-hooks/exhaustive-deps

	const clickHandler = (photo) => {
		//console.log("clickHandler");
		//setSelectedPhoto(photo);
		//setShowPhotoDialog(true);
	}

	const updateHandler = () => {
		//console.log("updateHandler");
		setUpdateFaces(!updateFaces);
	}

	const itemTemplate = (face) => {
		if(face.photo) {
			return (
				<div>
					<FullFaceWithRotation
						face={face}
						updateHandler={updateHandler}
						onClick={clickHandler}
					/>
				</div>
			);
		}
	}

	const photoTemplate = () => {
		if(selectedFace) {
			//console.log(selectedFace);
			return (
				<FullImageWithFaceBoxes
					imageSize={"large"}
					photo={selectedFace.photo}
					faces={[selectedFace]}
					showFaces={true}
					updateHandler={updateHandler}
					onClick={clickHandler}
					//maxHeight="60vh"
				/>
			);
		}
	}

	//const saveFace = (event) => {
	//	console.log(selectedFace);
	//	faceService.saveFace(selectedFace).then(data => {
	//		//console.log(data);
	//		setUpdateFaces(!updateFaces);
	//	});
	//}

	const removeFace = async (event) => {
		if(selectedFace) {
			faceService.deleteFace(selectedFace.id).then(data => {
				//console.log(data);
				setUpdateFaces(!updateFaces);
			});
		}
	}

	//const reanalyzePhoto = (event) => {
	//	console.log(selectedFace.photo);
	//	photoService.reanalyzePhoto(selectedFace.photo.id).then(data => {
	//	});
	//}

	const onPaginatorChange = (event) => {
		//console.log(event);
		setFirst(event.first);
		setPage(event.page);
		setPageSize(event.rows);
	}

	const onFaceGroupSave = (event) => {
		//console.log(event);
		//setSelectedFaceGroup(event.target.value);
		faceService.setFaceGroup(selectedFace.id, event.target.value.id).then(data => {
			setUpdateFaces(!updateFaces);
			setSelectedFace(null);
		});

	};

	const createNewFaceGroup = (event) => {
		setSubmitted(true);
		//console.log(newFaceGroup);
		faceGroupService.createFaceGroup({name: newFaceGroup, named: true}).then(data => {
			console.log(data);
			setOpenNewFaceGroup(false);
			setNewFaceGroup("");
			faceService.setFaceGroup(selectedFace.id, data.entity.id).then(data2 => {
				console.log(data2);
				setUpdateFaces(!updateFaces);
				setSelectedFace(null);
				setUpdateGroups(!updateGroups); // Updates the pulldown of facegroups
			});
		});
	};

	const changeNewFaceGroup = (event) => {
		setNewFaceGroup(event.target.value);
	};

	const handleOpenNewFaceGroup = (event) => {
		setOpenNewFaceGroup(true);
	};

	const closeNewFaceGroup = () => {
		setOpenNewFaceGroup(false);
		setSubmitted(false);
		setNewFaceGroup("");
	};

	const faceGroupDialogFooter = (
		<React.Fragment>
			<Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={closeNewFaceGroup} />
			<Button label="Save" icon="pi pi-check" className="p-button-text" onClick={createNewFaceGroup} />
		</React.Fragment>
	);

	return (
		<MainWithFooter>
			<div className="grid">
				<div className="col-12 md:col-6 xl:col-3">
					<Card title="Ungrouped Faces">
						<ListBox
							value={selectedFace}
							options={faces}
							onChange={(e) => {
								if(e.value) {
									setSelectedFace(e.value);
									setSelectedFaceGroup(null);
								}
							}}
							optionLabel="faceId"
							itemTemplate={itemTemplate}
							style={{ width: '100%' }}
							listStyle={{ maxHeight: '50vh' }}
						/>
						<Paginator
							first={first} rows={pageSize} totalRecords={totalFaces}
							rowsPerPageOptions={[2, 12, 18, 24, 48]} onPageChange={onPaginatorChange}
							template = {{ layout: 'PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport' }}
						/>
					</Card>
				</div>
				<div className="col-12 md:col-6 xl:col-9">
					<Card style={{ display: selectedFace ? "block" : "none"}}>
						<div className="grid">
							<div className="col-12">
								<div className="grid">
									<div className="col-5 md:col-6 xl:col-4">
										<Button label="Create New FaceGroup" icon="pi pi-plus" onClick={handleOpenNewFaceGroup} />
									</div>
									<div className="col-6 md:col-6 xl:col-3">
										<Dropdown
											value={selectedFaceGroup}
											options={faceGroups}
											onChange={onFaceGroupSave}
											placeholder={"Assign Face Group"}
											optionLabel="name"
											className='p-col-12'
											resetFilterOnHide="true"
											filter showClear filterBy="name"
										/>
									</div>
									<div className="col-3 md:col-6 xl:col-5">
										<div className="flex justify-content-end">
											<Button label="Remove Face" onClick={removeFace} />
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								{photoTemplate(selectedFace)}
							</div>
						</div>
					</Card>
				</div>
			</div>
			<Dialog visible={openNewFaceGroup} style={{ width: '450px' }} header="New Face Group" modal className="p-fluid" footer={faceGroupDialogFooter} onHide={closeNewFaceGroup}>
				<div className="field">
					<label htmlFor="name">Face Group Name</label>

					<InputText id="name" value={newFaceGroup}
						onChange={changeNewFaceGroup}
						required autoFocus className={classNames({ 'p-invalid': submitted && !newFaceGroup })}
					/>
					{submitted && !newFaceGroup && <small className="p-error">Face Group Name is required.</small>}
				</div>
			</Dialog>
		</MainWithFooter>
	);

}

