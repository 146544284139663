import React from 'react';

import { HashLink } from 'react-router-hash-link';

import '../assets/layout/diamond-layout/css/landing.scss';
import "../assets/css/Landing.scss";

import { YoutubeVideo } from '../components/YoutubeVideo';

import headerImage from "../assets/mainimage.png";
//import headerImage from "../assets/layout/diamond-layout/images/pages/landing/header-image.png";
import image1 from "../assets/layout/diamond-layout/images/pages/landing/icon-devices.svg";
import image2 from "../assets/layout/diamond-layout/images/pages/landing/icon-design.svg";
import image3 from "../assets/layout/diamond-layout/images/pages/landing/icon-document.svg";
import image4 from "../assets/layout/diamond-layout/images/pages/landing/icon-diamond.svg";

import image5 from "../assets/facerec.jpg";
import image6 from "../assets/feature1.png";
import image7 from "../assets/feature2.png";

//import image8 from "../assets/layout/diamond-layout/images/logo-white.svg";

export const LandingPage = () => {

	return (
		<>
			<div className="landing-header">
				<div className="landing-topbar">
					<div className="grid">
						<div className="col-12 sm:col-4 md:col-3 lg:col-3">
							<HashLink to="#home" className="logo">
								<img src="images/logo-white.svg" alt="Logo" />
								<h1>FaceGroup.io</h1>
							</HashLink>
						</div>


						<div className="col-12 sm:col-8 md:col-9 lg:col-7">
							<ul className="landing-menu">
								<li className="landing-menu-item">
									<HashLink to="#home">HOME</HashLink>
								</li>
								<li className="landing-menu-item">
									<HashLink to="#meetFaceGroup">MEET FACEGROUP</HashLink>
								</li>
								<li className="landing-menu-item">
									<HashLink to="#features">FEATURES</HashLink>
								</li>
								<li className="landing-menu-item">
									<HashLink to="#pricing">PRICING</HashLink>
								</li>
								<li className="contact landing-menu-item">
									<HashLink to="#contact">CONTACT</HashLink>
								</li>
							</ul>
						</div>
						<div className="col-12 md:col-8 lg:col-2">
							<ul className="contact-menu">
								<li>
									<HashLink to="#contact">CONTACT</HashLink>
								</li>
								<li>
									<a href="https://www.facebook.com/FaceGroup.io" target="_blank" rel="noreferrer"><i className="pi pi-facebook"></i></a>
								</li>
								<li>
									<a href="https://twitter.com/facegroupio" target="_blank" rel="noreferrer"><i className="pi pi-twitter"></i></a>
								</li>
								<li>
									<a href="https://www.youtube.com/channel/UC6WKSrk3Xv7_zbebW9p-Uxg" target="_blank" rel="noreferrer"><i className="pi pi-youtube"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="landing-header-content">
					<div className="grid">
						<div className="col-12 md:col-6">
							<div className="header-text" style={{ width: "100%" }}>
								<h1>FaceGroup.io - Cloud Photo Management</h1>
								<p>FaceGroup.io manages your photos, groups recognizied faces, and saves you time by searching through your photos. FaceGroup.io endeavors to be the best cloud for photos.</p>
								<a className="landing-btn" href="/#/subscription">
									<span>Log In / Sign Up /</span>
									<i className="p-button-icon pi pi-google px-2" style={{ color: "#1468b0" }}></i>
								</a>
							</div>
						</div>
						<div className="col-12 md:col-6">
							<div className="header-image" style={{ width: "100%", textAlign: "center" }}>
								<img className="animated" src={headerImage} alt="HeaderImage" style={{ maxWidth: "30vw" }}/>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div id="meetFaceGroup" className="landing-meetdiamond">
				<div className="section-title">
					<YoutubeVideo embedId={"-kNdMMi7p9M"} />
					<span>Backup photos to cloud</span>
				</div>
				<div className="grid">
					<div className="col-12 md:col-6 lg:col-3">
						<div className="card widget-feature-box">
							<img src={image2} alt="Image2" />
							<h2>Facial Recognition</h2>
							<span>Recognize faces in your photos and group them into FaceGroup's. FaceGroup.io provides photo storage with facial recognition.</span>
						</div>
					</div>
					<div className="col-12 md:col-6 lg:col-3">
						<div className="card widget-feature-box">
							<img src={image4} alt="Image4" />
							<h2>Photo Storage</h2>
							<span>Store all your photos in one place, and backup photos to cloud. FaceGroup.io can be your backup for photos.</span>
						</div>
					</div>
					<div className="col-12 md:col-6 lg:col-3">
						<div className="card widget-feature-box">
							<img src={image3} alt="Image3" />
							<h2>Photo Search Engine</h2>
							<span>Search your photos via Labels, FaceGroup's, or text to find exactly what you are looking for. FaceGroup.io provides facial recognition search through all your photos.</span>
						</div>
					</div>
					<div className="col-12 md:col-6 lg:col-3">
						<div className="card widget-feature-box">
							<img src={image1} alt="Image1" />
							<h2>FaceGroup App</h2>
							<span>Continuously send photos from your device into FaceGroup for cloud storage storage and Facial Recognition processing. Use FaceGroup.io's app for photo storage and cloud for photos.</span>
						</div>
					</div>
				</div>
			</div>


			<div id="features" className="landing-features">
				<div className="feature-row grid">
					<div className="col-12 md:col-4">
						<h2>Facial Recognition</h2>
						<span>FaceGroup.io uses state of the art Facial Recognition software to detect faces in photos.
							Label Detection and Text Detection are also run on each photo to gather as much data for searching.
							Every face is as unique as a finger print. Being also to group faces from infant all the way to mature adults.
						</span>
					</div>
					<div className="feature-image col-12 md:col-8">
						<img className="animated" src={image5} alt="Image5" />
					</div>
				</div>
				<div className="feature-row grid">
					<div className="col-12 md:col-4">
						<h2>Photo Storage</h2>
						<span>FaceGroup.io stores all photos in the cloud with the same competitive low prces as other providers.
							Photo security is most important, allowing the customer to share photos as they wish.</span>
					</div>
					<div className="feature-image col-12 md:col-8">
						<img className="animated" src={image6} alt="Image6" />
					</div>
				</div>
				<div className="feature-row grid">
					<div className="col-12 md:col-4">
						<h2>Photo Search Engine</h2>
						<span>Where FaceGroup.io does better, is give you the tools to search and classify your photos.
							Instead of having to search through folders and folders of pictures not finding the picture
							that you are looking for. Once all photos are FaceGrouped, Labeled and categorized find any
							photo quickly and save time.</span>
					</div>
					<div className="feature-image col-12 md:col-8">
						<img className="animated" src={image7} alt="Image7" />
					</div>
				</div>
			</div>


			<div id="pricing" className="landing-pricing">
				<div className="section-title">
					<h1>Our Pricing</h1>
					<span>The best price for our customers</span>
				</div>
				<div className="pricing-cards">
					<div className="widget-pricing-card">
						<h2>Plan 1</h2>
						<span>Starting from</span>
						<h3>$9.80</h3>
						<ul>
							<li>100GB storage</li>
							<li>4,500PUs / month</li>
							<li>Facial Recognition</li>
						</ul>
						<a href="/#/subscription">Sign Up</a>
					</div>
					<div className="widget-pricing-card deal">
						<h2>Plan 2</h2>
						<span>Starting from</span>
						<h3>$17.00</h3>
						<ul>
							<li>250GB storage</li>
							<li>7,500PUs / month</li>
							<li>Facial Recognition</li>
							<li>Label Detection</li>
							<li>Moderation Label Detection</li>
						</ul>
						<a href="/#/subscription">Sign Up</a>
					</div>
					<div className="widget-pricing-card">
						<h2>Plan 3</h2>
						<span>Starting from</span>
						<h3>$38.00</h3>
						<ul>
							<li>1000GB storage</li>
							<li>15,000PUs / month</li>
							<li>Facial Recognition</li>
							<li>Label Detection</li>
							<li>Moderation Label Detection</li>
							<li>Text Detection</li>
							<li>FaceGroup Synchronization App</li>
							<li>Download / Backup Photo Archive and Metadata</li>
							<li>Social Media FaceGrouping Integration</li>
						</ul>
						<a href="/#/subscription">Sign Up</a>
					</div>
				</div>
			</div>


			<div id="contact" className="landing-contact">
				<div className="section-title">
					<h1>Let’s Get In Touch</h1>
					<span>Just drop us an email</span>
				</div>
				<div className="grid">
					<div className="col-12 sm:col-6">
						<div className="contact-wrapper">
							<div className="contact-right" style={{ width: "100%" }}>
								<div className="contact-text">
									<h1>Feel free to contact us and we will get <br/>
										back to you <br/>
										as soon as we can.</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 sm:col-6">
						<div className="contact-wrapper">
							<div className="contact-left" style={{ width: "100%" }}>
							</div>
						</div>
					</div>

				</div>

				<div className="landing-footer">
					<HashLink to="#home" className="logo">
						<img src="images/logo-white.svg" alt="Logo" />
						<h1>FaceGroup.io</h1>
					</HashLink>
					<div className="social">
						<a href="https://www.facebook.com/FaceGroup.io" target="_blank" rel="noreferrer"><i className="pi pi-facebook"></i></a>
						<a href="https://twitter.com/facegroupio" target="_blank" rel="noreferrer"><i className="pi pi-twitter"></i></a>
						<a href="https://www.youtube.com/channel/UC6WKSrk3Xv7_zbebW9p-Uxg" target="_blank" rel="noreferrer"><i className="pi pi-youtube"></i></a>
					</div>
				</div>

				<div className="landing-mask"></div>
			</div>

		</>

	);

}
