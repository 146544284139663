import { useAuthenticator } from "@aws-amplify/ui-react";

export const RequireAuthToDisplay = ({ children }) => {

	const { user } = useAuthenticator((context) => [context.user]);

	if(user) {
		return children;
	} else {
		return (<></>);
	}
}
