import React, { useState } from 'react';
import { FullImageWithFaceBoxes } from '../components/FullImageWithFaceBoxes';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import { PhotoDialog } from '../components/PhotoDialog';


export const PhotoResults = (props) => {

	//onPaginatorChange={onPaginatorChange} pagination={pagination} displayOptions={displayOptions} photos={photos}

	const [selectedPhoto, setSelectedPhoto] = useState(null);
	const [showPhotoDialog, setShowPhotoDialog] = useState(false);

	const [showFaces, setShowFaces] = useState(props.displayOptions.showFaces);
	const [showPhotoLabels, setShowPhotoLabels] = useState(props.displayOptions.showPhotoLabels);
	const [showPhotoModerationLabels, setShowPhotoModerationLabels] = useState(props.displayOptions.showPhotoModerationLabels);
	const [showPhotoTexts, setShowPhotoTexts] = useState(props.displayOptions.showPhotoTexts);

	//console.log(props.displayOptions);

	const imagesTemplate = (photos) => {
		let ret = [];
		if(photos) {
			for(let photo of photos) {
				console.log(photo);
				ret.push(
					<div key={photo.id} className="col flex1">
						<FullImageWithFaceBoxes
							key={photo.id}
							imageSize={"small"}
							photo={photo}
							faces={photo.faces}
							labels={photo.labels}
							moderationLabels={photo.moderationLabels}
							texts={photo.texts}
							showFaces={showFaces}
							showPhotoLabels={showPhotoLabels}
							showPhotoTexts={showPhotoTexts}
							showPhotoModerationLabels={showPhotoModerationLabels}
							updateHandler={props.updateHandler}
							onClick={clickHandler} />
					</div>
				);
			}
		}
		return ret;
	}
	const clickHandler = (photo) => {
		//console.log(photo);
		setSelectedPhoto(photo);
		setShowPhotoDialog(true);
	}

	return (
		<>
			<div className="col-12">
				<Card title={props.title}>
					{ props.showSelectedItems ? props.showSelectedItems() : '' }
					{ props.showCheckboxes &&
						<div className="grid">
							<div className="col-5">
								<div className="grid">
									<div className="col-3 field-checkbox">
										<Checkbox inputId="cb1" onChange={(e) => setShowFaces(e.checked)} checked={showFaces} />
										<label htmlFor="cb1" className="p-checkbox-label">Show Faces</label>
									</div>
									<div className="col-3 field-checkbox">
										<Checkbox inputId="cb2" onChange={(e) => setShowPhotoLabels(e.checked)} checked={showPhotoLabels} />
										<label htmlFor="cb2" className="p-checkbox-label">Show Labels</label>
									</div>
									<div className="col-3 field-checkbox">
										<Checkbox inputId="cb4" onChange={(e) => setShowPhotoModerationLabels(e.checked)} checked={showPhotoModerationLabels} />
										<label htmlFor="cb4" className="p-checkbox-label">Show Moderation</label>
									</div>
									<div className="col-3 field-checkbox">
										<Checkbox inputId="cb3" onChange={(e) => setShowPhotoTexts(e.checked)} checked={showPhotoTexts} />
										<label htmlFor="cb3" className="p-checkbox-label">Show Texts</label>
									</div>
								</div>
							</div>
						</div>
					}
					<Paginator first={props.pagination.first} rows={props.pagination.limit} totalRecords={props.pagination.totalResults} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={(e) => props.onPaginatorChange(e)} />
					<div id="photos">
						<div className="grid">
							{imagesTemplate(props.photos)}
						</div>
					</div>
					<Paginator first={props.pagination.first} rows={props.pagination.limit} totalRecords={props.pagination.totalResults} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={(e) => props.onPaginatorChange(e)} />
				</Card>
			</div>
			<PhotoDialog
				displayOptions={props.displayOptions}
				showPhotoDialog={showPhotoDialog}
				setShowPhotoDialog={setShowPhotoDialog}
				selectedPhoto={selectedPhoto}
				updateHandler={props.updateHandler}
			/>
		</>

	);
}
