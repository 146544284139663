import React, { useRef, useState, useEffect } from 'react';

import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import CryptoJS from "crypto-js";

import { PhotoDialog } from '../components/PhotoDialog';
import { FullImageWithFaceBoxes } from '../components/FullImageWithFaceBoxes';

import { PhotoService } from '../service/PhotoService';
import { S3UploadService } from '../service/S3UploadService';

import { MainWithFooter } from '../components/MainWithFooter';

import '../assets/css/PhotosInboxPage.scss';

export const PhotosInboxPage = () => {
	const [totalSize, setTotalSize] = useState(0);
	const toast = useRef(null);
	const fileUploadRef = useRef(null);
	const [photos, setPhotos] = useState([]);

	const [totalPhotos, setTotalPhotos] = useState(0);
	const [pageSize, setPageSize] = useState(12);
	const [page, setPage] = useState(0);
	const [first, setFirst] = useState(0);

	const [selectedPhoto, setSelectedPhoto] = useState(null);
	const [showPhotoDialog, setShowPhotoDialog] = useState(false);

	const [showFaces, setShowFaces] = useState(true);
	const [showPhotoLabels, setShowPhotoLabels] = useState(false);
	const [showPhotoModerationLabels, setShowPhotoModerationLabels] = useState(false);
	const [showPhotoTexts, setShowPhotoTexts] = useState(false);

	const [updatePhotoList, setUpdatePhotoList] = useState(false);

	const photoService = new PhotoService();
	const s3UploadService = new S3UploadService();

	useEffect(() => {
		photoService.inboxPhotos(page, pageSize).then(data => {
			if(data.totalResults > 0) {
				setPhotos(data.results);
				// for(let photo in data.results) {
				// 	if(selectedPhoto && selectedPhoto.id === photo.id) {
				// 		setSelectedPhoto(photo);
				// 	}
				// }
			} else {
				setPhotos([]);
			}
			setTotalPhotos(data.totalResults);
		});
	}, [page, pageSize, updatePhotoList]); // eslint-disable-line react-hooks/exhaustive-deps

	const onTemplateSelect = (e) => {
		let _totalSize = totalSize;

		Array.from(e.files).forEach(file => {
			_totalSize += file.size;
		});

		setTotalSize(_totalSize);
	}

	const clickHandler = (photo) => {
		//console.log("clickHandler");
		setSelectedPhoto(photo);
		setShowPhotoDialog(true);
	}

	const updateHandler = () => {
		//console.log("updateHandler");
		setUpdatePhotoList(!updatePhotoList);
	}

	const onPaginatorChange = (event) => {
		//console.log(event);
		setFirst(event.first);
		setPage(event.page);
		setPageSize(event.rows);
	 }

	const onTemplateRemove = (file, callback) => {
		setTotalSize(totalSize - file.size);
		callback();
	}

	const onTemplateUpload = (e) => {
		let _totalSize = 0;
		console.log(e);
		Array.from(e.files).forEach(file => {
			_totalSize += (file.size || 0);
		});

		setTotalSize(_totalSize);
		toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
	}

	const onTemplateClear = () => {
		setTotalSize(0);
	}

	const headerTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;
		const value = totalSize/10000000;
		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

		return (
			<div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
				{chooseButton}
				{uploadButton}
				{cancelButton}
				<ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 GB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar>
			</div>
		);
	}

	const itemTemplate = (file, props) => {
		return (
			<div className="flex align-items-center flex-wrap">
				<div className="flex align-items-center" style={{width: '40%'}}>
					<img alt={file.name} role="presentation" src={file.objectURL} width={100} />
					<span className="flex flex-column text-left ml-3">
						{file.name}
						<small>{new Date().toLocaleDateString()}</small>
					</span>
				</div>
				<Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
			</div>
		)
	}

	const emptyTemplate = () => {
		return (
			<div className="flex align-items-center flex-column">
				<i className="pi pi-image" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
				<span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}>Drag and Drop Image Here</span>
			</div>
		)
	}

	const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
	const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
	const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

	const imagesTemplate = (photos) => {
		let ret = [];
		for(let photo of photos) {
			//console.log(photo.faces);
			ret.push(
				<div key={photo.id} className="col-6 md:col-4 xl:col-3">
					<FullImageWithFaceBoxes
						key={photo.id}
						imageSize={"small"}
						photo={photo}
						faces={photo.faces} labels={photo.labels} moderationLabels={photo.moderationLabels} texts={photo.texts}
						showFaces={showFaces} showPhotoLabels={showPhotoLabels} showPhotoTexts={showPhotoTexts} showPhotoModerationLabels={showPhotoModerationLabels}
						updateHandler={updateHandler}
						onClick={clickHandler} />
				</div>
			);
		}
		return ret;
	}


	const getMd5 = (file) => {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();

			reader.onload = () => {
				resolve(CryptoJS.MD5(CryptoJS.enc.Latin1.parse(reader.result)).toString());
				//resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsBinaryString(file);
		});
	}


	const uploadHandler = async (event) => {
		console.log("Uploading Files: " + event.files.length);
		for(let file of event.files) {
			let md5Sum = await getMd5(file);
			s3UploadService.uploadPhoto(md5Sum, file);
		}
		event.options.clear();
	}

	return (
		<MainWithFooter>
			<div className="grid">
				<Toast ref={toast}></Toast>
				<div className="col-12">
					<Card title="File Upload">
						<FileUpload
							ref={fileUploadRef} name="uploads"
							multiple
							accept="image/*"
							maxFileSize={1000000000}
							onUpload={onTemplateUpload}
							onSelect={onTemplateSelect}
							//withCredentials={true}
							onError={onTemplateClear}
							onClear={onTemplateClear}
							headerTemplate={headerTemplate}
							itemTemplate={itemTemplate}
							emptyTemplate={emptyTemplate}
							chooseOptions={chooseOptions}
							uploadOptions={uploadOptions}
							cancelOptions={cancelOptions}
							customUpload uploadHandler={uploadHandler}
						/>
					</Card>
				</div>
				{ photos.length > 0 &&
					<div className="col-12">
						<Card title="Reciently Uploaded Pictures">
							<div className="grid">
								<div className="col-12">
									<div className="grid">
										<div className="col-3 field-checkbox">
											<Checkbox inputId="cb1" onChange={(e) => setShowFaces(e.checked)} checked={showFaces} />
											<label htmlFor="cb1" className="p-checkbox-label">Show Faces</label>
										</div>
										<div className="col-3 field-checkbox">
											<Checkbox inputId="cb2" onChange={(e) => setShowPhotoLabels(e.checked)} checked={showPhotoLabels} />
											<label htmlFor="cb2" className="p-checkbox-label">Show Labels</label>
										</div>
										<div className="col-3 field-checkbox">
											<Checkbox inputId="cb4" onChange={(e) => setShowPhotoModerationLabels(e.checked)} checked={showPhotoModerationLabels} />
											<label htmlFor="cb4" className="p-checkbox-label">Show Moderation</label>
										</div>
										<div className="col-3 field-checkbox">
											<Checkbox inputId="cb3" onChange={(e) => setShowPhotoTexts(e.checked)} checked={showPhotoTexts} />
											<label htmlFor="cb3" className="p-checkbox-label">Show Texts</label>
										</div>
									</div>
								</div>
							</div>
							<Tooltip target=".leftIcon" mouseTrack mouseTrackLeft={10}/>
							<Tooltip target=".rightIcon" mouseTrack mouseTrackLeft={10}/>
							<Paginator first={first} rows={pageSize} totalRecords={totalPhotos} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={onPaginatorChange} />
							<div className="grid" id="photos">
								{imagesTemplate(photos)}
							</div>
							<Paginator first={first} rows={pageSize} totalRecords={totalPhotos} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={onPaginatorChange} />
						</Card>
					</div>
				}
				<PhotoDialog
					displayOptions={{showFaces: showFaces, showPhotoLabels: showPhotoLabels, showPhotoModerationLabels: showPhotoModerationLabels, showPhotoTexts: showPhotoTexts}}
					showPhotoDialog={showPhotoDialog}
					setShowPhotoDialog={setShowPhotoDialog}
					updateHandler={updateHandler}
					selectedPhoto={selectedPhoto}
				/>
			</div>
		</MainWithFooter>

	);
}
