import React from 'react';
import { AppFooter } from '../AppFooter';
import { useStickyState } from "../sitefunctions";

export const MainWithFooter = ({ children }) => {

	const [layoutColorMode] = useStickyState('light', 'layoutColorMode');

	return (
		<div className="layout-main-container">
			<div className="layout-main">
				{children}
			</div>

			<AppFooter layoutColorMode={layoutColorMode} />
		</div>
	);
}
