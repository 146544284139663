import React, { useEffect } from 'react';
//import { useAuth0 } from "@auth0/auth0-react";
import { MainWithFooter } from '../components/MainWithFooter';

export const WelcomePage = () => {

	//const { user } = useAuth0();

	useEffect(() => {
		// Get the amount of the subscription from the user object
		//console.log(user);
		window.gtag('event', 'conversion', {
			'send_to': 'AW-979120804/PjFGCIzFyrsDEKTl8NID',
			'value': 10.00,
			'currency': 'USD'
		 });
	}, []);

	return (
		<MainWithFooter>
			<div border="1px solid red">Welcome Page</div>
		</MainWithFooter>
	);
}
