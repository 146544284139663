import axios from 'axios';

export class BaseAuthService {

	api;
	apiHost = process.env.REACT_APP_API_URL;

	constructor() {
		const idToken = localStorage.getItem("idToken");
		if(idToken) {
			this.api = axios.create({
				//withCredentials: true,
				baseURL: this.apiHost,
				headers: {
					//"Referrer-Policy": "no-referrer",
					Authorization: `${idToken}`
				}
			});
		} else {
			console.log("No idToken");
		}
	}

}
