import { BaseAuthService } from './BaseAuthService';

export class FaceService extends BaseAuthService {

	getUnassociatedFaces(page, pageSize) {
		return this.api.get(`/face/unassociated/group?page=${page}&pageSize=${pageSize}`).then(res => res.data);
	}

	getFace(faceId) {
		return this.api.get(`/face/${faceId}`).then(res => res.data);
	}

	setFaceGroup(faceId, groupId) {
		return this.api.get(`/face/${faceId}/associategroup/${groupId}`).then(res => res.data);
	}

	deleteFace(faceId) {
		return this.api.delete(`/face/${faceId}`).then(res => res.data);
	}

	reanalyzeFace(faceId) {
		return this.api.get(`/face/${faceId}/reanalyze`).then(res => res.data);
	}
}
