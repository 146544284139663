
import { useAuthenticator } from "@aws-amplify/ui-react";

//import { Subscription } from '../pages/Subscription';

export const RequireSubscription = ({ children }) => {

	const { user } = useAuthenticator((context) => [context.user]);

	if(!user || !user["https://www.facegroup.io/stripe_subscriptions"] || user["https://www.facegroup.io/stripe_subscriptions"].length === 0) {
		return children;
		//return (<Subscription />);
	} else {
		return children;
	}
}
