import React from 'react';

import { PhotoService } from '../service/PhotoService';

import '../assets/css/FullImageWithFaceBoxes.scss';


export const FullImageWithFaceBoxes = (props) => {

	const photoService = new PhotoService();

	const faceBoundingBoxes = (faces) => {
		if(faces && faces.length > 0) {
			let ret = [];
			let keyCounter = 0;

			for(let faceRecord of faces) {

				let style = {
					position: "absolute",
					top: faceRecord.face.boundingBox.top * 100 + "%",
					left: faceRecord.face.boundingBox.left * 100 + "%",
					height: faceRecord.face.boundingBox.height * 100 + "%",
					width: faceRecord.face.boundingBox.width * 100 + "%",
					border: "2px solid rgba(255, 255, 255, 0.69)",
					borderRadius: "20%",
					//textAlign: "center",
					//alignItems: "center"
				}

				//color: var(--primary-color);

				//let span = <span >Face Text</span>
				let svgElement = '';

				let groupName = '';

				if(props.group) {
					groupName = props.group;
				}

				//if(face.group) {
				//	groupName = face.group.name;
				//}

				if(groupName && props.showFaces) {

					if(props.photo.rotation === 270) {
						//Right Side
						svgElement = (
							<div style={{ display: "flex", justifyContent: "right", height: "100%" }}>
								<div style={{ fontSize: "1vh", backgroundColor: "white", color: "black", writingMode: "vertical-rl" }} id={faceRecord.face.faceId}>
									<span>{groupName}</span>
								</div>
							</div>
						);
					}

					if(props.photo.rotation === 0) {
						// Top Side
						svgElement = (
							<div>
								<div style={{ fontSize: "0.7vh", backgroundColor: "white", color: "black" }} id={faceRecord.face.faceId}>
									<span>{groupName}</span>
								</div>
							</div>
						);
					}

					if(props.photo.rotation === 90) {
						// Left Side
						svgElement = (
							<div style={{ display: "flex", justifyContent: "left", height: "100%" }}>
								<div style={{ fontSize: "1vh", backgroundColor: "white", color: "black", writingMode: "vertical-rl", transform: "rotate(180deg)" }} id={faceRecord.face.faceId}>
									<span>{groupName}</span>
								</div>
							</div>
						);
					}

					if(props.photo.rotation === 180) {
						//Bottom Side
						svgElement = (
							<div>
								<div style={{ fontSize: "0.7vh", backgroundColor: "white", color: "black", position: "absolute", bottom: "0", transform: "rotate(180deg)", width: "100%" }} id={faceRecord.face.faceId}>
									<span>{groupName}</span>
								</div>
							</div>
						);
					}

				}

				if(props.showFaces) {
					ret.push(
						<div faceid={faceRecord.face.faceId} key={keyCounter} style={style}>
							{svgElement}
						</div>
					);
				}

				if(props.showLandmarks) {
					let landmarks = [];

					for(let landmark of faceRecord.faceDetail.landmarks) {
						//console.log(landmark);
						landmarks.push(
							<div key={landmark.type} style={{ position: "absolute", left: `calc(${landmark.x * 100}% - 2px)`, top: `calc(${landmark.y * 100}% - 2px)` }}>
								<svg key={landmark.type} style={{width: "10px", height: "10px"}}>
									<circle cx="2" cy="2" r="2" fill="rgba(255, 255, 255, 0.69)" />
								</svg>
							</div>
						);
					}
					ret.push(landmarks);
				}

				keyCounter += 1;
			}
			//console.log(span.current);

			return ret;
		}
	}

	const textBoundingBoxes = (texts) => {
		if(texts && props.showPhotoTexts) {
			let ret = [];
			let keyCounter = 0;

			for(let textDetection of texts) {

				if(textDetection.type === "WORD") {
					let style = {
						position: "absolute",
						top: textDetection.geometry.boundingBox.top * 100 + "%",
						left: textDetection.geometry.boundingBox.left * 100 + "%",
						height: textDetection.geometry.boundingBox.height * 100 + "%",
						width: textDetection.geometry.boundingBox.width * 100 + "%",
						border: "2px solid rgba(255, 255, 255, 0.69)",
						textAlign: "center",
						alignItems: "center"
					}

					let svgElement = (
						<svg style={{ verticalAlign: "top" }} className="top-auto" viewBox="0 0 85 15">
							<text style={{ textAnchor: "middle" }} x="50%" y="100%" >{textDetection.detectedText}</text>
						</svg>
					);

					ret.push(
						<div key={keyCounter} style={style}>{svgElement}</div>
					);

					//console.log(textDetection);
					keyCounter += 1;
				}
			}

			return ret;

		}
	}

	const labelBoundingBoxes = (labels) => {
		//console.log(photoLabels);
		//console.log(props.showPhotoLabels);
		if(labels && props.showPhotoLabels) {
			let ret = [];
			let keyCounter = 0;

			//console.log(labels);

			for(let label of labels) {

				//console.log(label);

				if(label.instances) {

					for(let instance of label.instances) {
						//console.log(instance);
						let style = {
							position: "absolute",
							top: instance.boundingBox.top * 100 + "%",
							left: instance.boundingBox.left * 100 + "%",
							height: instance.boundingBox.height * 100 + "%",
							width: instance.boundingBox.width * 100 + "%",
							border: "2px solid rgba(255, 255, 255, 0.69)",
							borderRadius: "20%",
							textAlign: "center",
							alignItems: "center"
						}

						// let svgElement = (
						// 	<svg style={{ verticalAlign: "top" }} className="top-auto" viewBox="0 0 250 20">
						// 		<rect x="0" y="0" width="100%" height="100%" fill="white"/>
						// 		<text style={{ textAnchor: "middle" }} x="50%" y="75%" >{photoLabel.label.name}</text>
						// 	</svg>
						// );
						let svgElement = '';

						//console.log(props.photo.rotation);
						if(props.photo.rotation === 270) {
							//Right Side
							svgElement = (
								<div style={{ display: "flex", justifyContent: "right", height: "100%" }}>
									<div style={{ fontSize: "1vh", backgroundColor: "white", color: "black", writingMode: "vertical-rl" }} id={label.id}>
										<span>{label.name}</span>
									</div>
								</div>
							);
						}

						if(props.photo.rotation === 0) {
							// Top Side
							svgElement = (
								<div>
									<div style={{ fontSize: "0.7vh", backgroundColor: "white", color: "black" }} id={label.id}>
										<span>{label.name}</span>
									</div>
								</div>
							);
						}

						if(props.photo.rotation === 90) {
							// Left Side
							svgElement = (
								<div style={{ display: "flex", justifyContent: "left", height: "100%" }}>
									<div style={{ fontSize: "1vh", backgroundColor: "white", color: "black", writingMode: "vertical-rl", transform: "rotate(180deg)" }} id={label.id}>
										<span>{label.name}</span>
									</div>
								</div>
							);
						}

						if(props.photo.rotation === 180) {
							//Bottom Side
							svgElement = (
								<div>
									<div style={{ fontSize: "0.7vh", backgroundColor: "white", color: "black", position: "absolute", bottom: "0", transform: "rotate(180deg)", width: "100%" }} id={label.id}>
										<span>{label.name}</span>
									</div>
								</div>
							);
						}













						ret.push(
							<div key={keyCounter} style={style}>{svgElement}</div>
						);
						keyCounter += 1;
					}

				}

			}
			return ret;
		}
	}

	const getModerationLabelBorder = () => {
		if(props.photo.photoModerationLabels && props.showPhotoModerationLabels) {
			for(let label of props.photo.photoModerationLabels) {
				if(label.confidence > 90) {
					//console.log(label);
					//console.log(props.photo);
					return "10px solid red";
				}
			}
		}
		return "";
	}

	const getPhotoLink = () => {

		if(props.imageSize) {
			if(props.imageSize === "small") {
				return props.photo.s3PathSmall ? filterHash(props.photo.s3PathSmall) : props.photo.s3Path;
			}
			if(props.imageSize === "large") {
				return props.photo.s3PathLarge ? filterHash(props.photo.s3PathLarge) : props.photo.s3Path;
			}
			if(props.imageSize === "medium") {
				return props.photo.s3PathMedium ? filterHash(props.photo.s3PathMedium) : props.photo.s3Path;
			}
			if(props.imageSize === "thumb") {
				return props.photo.s3PathThumb ? filterHash(props.photo.s3PathThumb) : props.photo.s3Path;
			}
		} else {
			return props.photo.s3PathSmall ? filterHash(props.photo.s3PathSmall) : props.photo.s3Path;
		}
	}

	const filterHash = (url) => {
		return url.replace("#", "%23");
	}

	const onClickHandler = () => {
		if(props.onClick) {
			props.onClick(props.photo);
		}
	}

	const rotateLeft = (e) => {
		e.stopPropagation();
		photoService.rotateLeft(props.photo.sk).then(data => {
			props.photo.rotation = data.entity.rotation;
			if(props.updateHandler) {
				props.updateHandler();
			}
		});
	}

	const rotateRight = (e) => {
		e.stopPropagation();
		photoService.rotateRight(props.photo.sk).then(data => {
			props.photo.rotation = data.entity.rotation;
			if(props.updateHandler) {
				props.updateHandler();
			}
		});
	}

	if(props.photo.rotation === 90 || props.photo.rotation === 270) {
		return (
			<div style={{ textAlign: "center" }}>
				<div style={{ display: "table" }}>
					<div style={{ padding: "50% 0", height: 0 }}>
						<div key={props.photo.pk} className={"rotate" + props.photo.rotation} style={{ position: "relative", display: "inline-block", justifyContent: "center" }} onClick={ onClickHandler } >
							<img src={`https://photos.facegroup.io/${getPhotoLink()}`} alt={props.photo.originalFileName} style={{ border: getModerationLabelBorder() }} />
							<div>
								<i className="rotate leftIcon pi pi-arrow-down-left" data-pr-tooltip="Rotate Left" onClick={rotateLeft} />
								<i className="rotate rightIcon pi pi-arrow-down-right" data-pr-tooltip="Rotate Right" onClick={rotateRight} />
								{faceBoundingBoxes(props.faces)}
								{textBoundingBoxes(props.texts)}
								{labelBoundingBoxes(props.labels)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div style={{ textAlign: "center" }}>
				<div key={props.photo.pk} className={"rotate" + props.photo.rotation} style={{ position: "relative", display: "inline-block", justifyContent: "center" }} onClick={ onClickHandler } >
					<img src={`https://photos.facegroup.io/${getPhotoLink()}`} alt={props.photo.originalFileName} style={{ maxHeight: props.maxHeight, maxWidth: "100%", border: getModerationLabelBorder() }} />
					<div>
						<i className="rotate leftIcon pi pi-arrow-down-left" data-pr-tooltip="Rotate Left" onClick={rotateLeft} />
						<i className="rotate rightIcon pi pi-arrow-down-right" data-pr-tooltip="Rotate Right" onClick={rotateRight} />
						{faceBoundingBoxes(props.faces)}
						{textBoundingBoxes(props.texts)}
						{labelBoundingBoxes(props.labels)}
					</div>
				</div>
			</div>
		);
	}

}

