import axios from 'axios';

export class S3UploadService {

	uploadPhoto(md5Sum, file) {
		let requestData = {
			orignalFileName: file.name,
			contentType: file.type,
			fileSize: file.size,
			md5Sum: md5Sum
		};
		//let accessToken = localStorage.getItem("accessToken");
		let idToken = localStorage.getItem("idToken");
		let options = { headers: { Authorization: `${idToken}` } };
		axios.post(process.env.REACT_APP_API_URL + `/photo/upload`, requestData, options)
			.then(res => {
				let options = { headers: { "Content-Type": file.type, "x-amz-storage-class": "GLACIER_IR" } };
				axios.put(res.data.entity.signedS3Url, file, options)
					.then(res => {
						console.log("Upload complete");
          			console.log(res.data);
					});
			});
	}

}
