
import React, { useEffect, useState } from 'react';

//import { useAuth0 } from "@auth0/auth0-react";

import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { MainWithFooter } from '../components/MainWithFooter';
import { SummaryStatsService } from '../service/SummaryStatsService';
import { LocalUserService } from '../service/LocalUserService';

export const ProfilePage = () => {

	//const { user } = useAuth0();

	const [summaryStats, setSummaryStats] = useState({});
	const [localUser, setLocalUser] = useState({localUserSetting:{}});

	//const [processFaces, setProcessFaces] = useState(false);
	//const [processLabels, setProcessLabels] = useState(false);
	//const [processTexts, setProcessTexts] = useState(false);

	const summaryStatsService = new SummaryStatsService();
	const localUserService = new LocalUserService();

	useEffect(() => {
		//console.log("Use effect running");
		summaryStatsService.stats().then(data => {
			//console.log(data.entity);
			setSummaryStats(data.entity);
		});
		localUserService.getLocalUser().then(data => {
			//console.log(data.entity);
			setLocalUser(data.entity);
			//setProcessFaces(data.entity.localUserSetting.processFaces);
			//setProcessLabels(data.entity.localUserSetting.processLabels);
			//setProcessTexts(data.entity.localUserSetting.processTexts);
		});

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const changedProcessFaces = (e) => {
		const _localUser = { ...localUser };
		_localUser.localUserSetting.processFaces = e.checked;
		localUserService.saveSettings(_localUser.localUserSetting).then(data => {
			_localUser.localUserSetting = data.entity;
			setLocalUser(_localUser);
		});
	}

	const changedProcessLabels = (e) => {
		const _localUser = { ...localUser };
		_localUser.localUserSetting.processLabels = e.checked;
		localUserService.saveSettings(_localUser.localUserSetting).then(data => {
			_localUser.localUserSetting = data.entity;
			setLocalUser(_localUser);
		});
	}

	const changedProcessTexts = (e) => {
		const _localUser = { ...localUser };
		_localUser.localUserSetting.processTexts = e.checked;
		localUserService.saveSettings(_localUser.localUserSetting).then(data => {
			_localUser.localUserSetting = data.entity;
			setLocalUser(_localUser);
		});
	}

	return (
		<MainWithFooter>
			<div className="grid">
				<div className="col-12 md:col-6 lg:col-6">
					<Card title="Profile">
						<h5>Id</h5>
						{"user.sub"}
						<h5>Name</h5>
						{"user.name"}
						<h5>Email</h5>
						{"user.email"}
						<h5>Nickname</h5>
						{"user.nickname"}
						<h5>Picture</h5>
						<img src={"user.picture"} alt={"user.name"} referrerPolicy="no-referrer" />
					</Card>
					<br/>
					<Card title="Settings">
						<h5><label htmlFor="cb1" className="p-checkbox-label">Process Faces</label> <Checkbox onChange={(e) => { changedProcessFaces(e) }} inputId="cb1" checked={localUser.localUserSetting.processFaces}></Checkbox></h5>
						<h5><label htmlFor="cb2" className="p-checkbox-label">Process Labels</label> <Checkbox onChange={(e) => changedProcessLabels(e)} inputId="cb2" checked={localUser.localUserSetting.processLabels}></Checkbox></h5>
						<h5><label htmlFor="cb3" className="p-checkbox-label">Process Text</label> <Checkbox onChange={(e) => changedProcessTexts(e)} inputId="cb3" checked={localUser.localUserSetting.processTexts}></Checkbox></h5>

					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-6">
					<Card title="Summary Stats">
						<div className="grid">

							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">PPU (Photo Processing Units)</span>
											<div className="text-900 font-medium text-xl">{summaryStats.processingAmount} of {summaryStats.processingLimit} = {summaryStats.processingAmount / summaryStats.processingLimit * 100}%</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-cog text-green-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">File Size Usage</span>
											<div className="text-900 font-medium text-xl">{summaryStats.fileSizeAmount}GB of {summaryStats.fileSizeLimit}GB = {summaryStats.fileSizeAmount / summaryStats.fileSizeLimit * 100}%</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-copy text-blue-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Face Group Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.faceGroupCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-sitemap text-orange-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Face Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.faceCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-bluegray-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-users text-bluegray-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Photo Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.photoCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-teal-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-images text-teal-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Label Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.labelCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-yellow-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-clone text-yellow-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Moderation Label Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.moderationLabelCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-pink-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-ban text-pink-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
									<div className="flex justify-content-between mb-3">
										<div>
											<span className="block text-500 font-medium mb-3">Text Count</span>
											<div className="text-900 font-medium text-xl">{summaryStats.textCount}</div>
										</div>
										<div className="flex align-items-center justify-content-center bg-indigo-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
											<i className="pi pi-tag text-indigo-500 text-xl"></i>
										</div>
									</div>
								</div>
							</div>

						</div>

					</Card>
				</div>
			</div>
		</MainWithFooter>
	);

}
