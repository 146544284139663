import React from 'react';

export const SearchCenterPage = ({ children }) => {

	return (
		<div className="grid">
			<div className="col-4 col-offset-4">
				<div className="grid min-h-screen">
					<div className="col-12">
						<div style={{ paddingTop: "20vh" }}>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
