import React, { useState, useEffect } from 'react';
import { MainWithFooter } from '../components/MainWithFooter';
import { AutoComplete } from 'primereact/autocomplete';
import { PhotoService } from '../service/PhotoService';
import { AutoCompleteService } from '../service/AutoCompleteService';
import { PhotoResults } from '../components/PhotoResults';
import { SearchCenterPage } from '../components/SearchCenterPage';

import "../assets/css/SearchPage.scss";

export const SearchPage = () => {

	const [searchString, setSearchString] = useState('');

	const [searchQueryString, setSearchQueryString] = useState(null);

	let initPagination = { page: 0, limit: 12, totalResults: 0};

	const [ pagination, setPagination] = useState(initPagination);

	const autoCompleteService = new AutoCompleteService();
	const photoService = new PhotoService();

	const [photos, setPhotos] = useState([]);

	const [ displayOptions] = useState({
		showFaces: true,
		showPhotoLabels: false,
		showPhotoModerationLabels: false,
		showPhotoTexts: false,
	});

	const [suggestions, setSuggestions] = useState([]);

	//let searchFields = ["faces.group.name", "photoLabels.label.name", "photoTexts.textDetection"];

	const doAutoComplete = (e) => {
		//console.log(e);
		autoCompleteService.autoComplete(e.query).then(data => {
			const array = Array.from(data.results, (object) => {
				return object.name;
			});
			setSuggestions(array);
		});
	}

	useEffect(() => {
		//setPagination(initPagination);
	}, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let _searchOptions = { };

		let searchFilters = {};

		searchFilters["searchFilter"] = {};
		searchFilters["searchFilter"]["faces.group.name"] = {
			queryString: searchString,
			//tokenOperator: "OR"
		}

		//searchFilters["labelFilter"] = {};
		searchFilters["searchFilter"]["photoLabels.label.name"] = {
			queryString: searchString,
			//tokenOperator: "OR"
		}

		//searchFilters["textFilter"] = {};
		searchFilters["searchFilter"]["photoTexts.textDetection"] = {
			queryString: searchString,
			//tokenOperator: "OR"
		}

		_searchOptions.searchFilters = searchFilters;

		photoService.search(pagination.page, pagination.limit, _searchOptions).then(data => {
			//console.log(data);
			setPhotos(data.results);

			let _pagination = {
				...pagination,
				totalResults: data.totalResults
			};

			setPagination(_pagination);
		});

	}, [searchQueryString, pagination.page, pagination.limit]); // eslint-disable-line react-hooks/exhaustive-deps

	const showSelectedItems = (props) => {

	}

	const onPaginatorChange = (event) => {
		//console.log(event);
		let _pagination = {
			...pagination,
			limit: event.rows,
			page: event.page,
			first: event.first
		};
		setPagination(_pagination);
	}

	return (
		<MainWithFooter>
			{ !searchQueryString &&
				<SearchCenterPage>
					<div className="search-box flex align-items-center justify-content-center">FaceGroup</div>
					<div>
						<span className="p-input-icon-left" style={{ width: "100%" }}>
							<i className="pi pi-search" style={{ zIndex: 1 }}/>
							<AutoComplete
								style={{ width: "100%" }}
								inputStyle={{ width: "100%" }}
								className={"p-input-icon-left"}
								value={searchString}
								delay={100}
								suggestions={suggestions}
								completeMethod={doAutoComplete}
								onKeyUp={(e) => {
									if(e.keyCode === 13) {
										setSearchQueryString(searchString);
									}
								}}
								onSelect={(e) => {
									console.log("Select: ");
									setSearchString(e.value);
									setSearchQueryString(searchString);
								}}
								onChange={(e) => {
									setSearchString(e.value);
								}}
								placeholder="Search">
							</AutoComplete>
						</span>
					</div>
				</SearchCenterPage>
			}
			{ searchQueryString &&
				<>
					<div>
						<div className="grid">
							<div className="sm:col-3 md:col-2 lg:col-2 xl:col-1">
								<div className="search-box-small">Search</div>
							</div>
							<div className="sm:col-7 md:col-6 lg:col-4 xl:col-3">
								<span className="p-input-icon-left" style={{ width: "100%" }}>
									<i className="pi pi-search" style={{ zIndex: 1 }}/>
									<AutoComplete
										style={{ width: "100%" }}
										inputStyle={{ width: "100%" }}
										className={"p-input-icon-left"}
										value={searchString}
										delay={100}
										suggestions={suggestions}
										completeMethod={doAutoComplete}
										onKeyUp={(e) => {
											if(e.keyCode === 13) {
												setSearchQueryString(searchString);
											}
										}}
										onSelect={(e) => {
											console.log("Select: ");
											setSearchString(e.value);
											setSearchQueryString(searchString);
										}}
										onChange={(e) => {
											setSearchString(e.value);
										}}
										placeholder="Search">
									</AutoComplete>
								</span>
							</div>

						</div>

						<PhotoResults title={"Photos: " + searchQueryString} showSelectedItems={showSelectedItems} onPaginatorChange={onPaginatorChange} pagination={pagination} displayOptions={displayOptions} photos={photos} showCheckboxes={false} />
					</div>
				</>
			}
		</MainWithFooter>
	);
}


