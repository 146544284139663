
import React from 'react';
import { Button } from 'primereact/button';
import { PhotoService } from '../../service/PhotoService';

export const ReprocessLabelsButton = (props) => {

	const photoService = new PhotoService();

	const reprocessLabels = () => {
		photoService.reprocessLabels(props.photo.id).then(data => {
			//console.log(data);
		});
	}

	return (
		<Button label="Reprocess Labels" onClick={reprocessLabels}></Button>
	);
}
