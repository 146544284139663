import React, { useRef } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';

export const AppTopbar = (props) => {

	const menu = useRef(null);
	const menuItems = [
		{
			items: [
				{
					label: 'Profile',
					icon: 'pi pi-profile',
					command:(e) => { window.location.hash = "/profile" }
				}, {
					label: 'Logout',
					icon: 'pi pi-sign-out',
					command: props.logout
				}
			]
		}
	];

	return (
		<div className="layout-topbar">
			<Link to="/" className="layout-topbar-logo">
				<img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo"/>
				<span>Face Group</span>
			</Link>
			<button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
				<i className="pi pi-bars"/>
			</button>

			<button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
				<i className="pi pi-ellipsis-v" />
			</button>
			<ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
				<li>
					<button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
						<i className="pi pi-calendar"/>
						<span>Events</span>
					</button>
				</li>
				<li>
					<button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
						<i className="pi pi-cog"/>
						<span>Settings</span>
					</button>
				</li>
				<li>
					<button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
						<Menu model={menuItems} popup ref={menu} id="popup_menu" />
						<i className="pi pi-user" onClick={(event) => menu.current.toggle(event)} />
					</button>
				</li>
			</ul>
		</div>
	);
}
