import { BaseAuthService } from './BaseAuthService';

export class PhotoService extends BaseAuthService {

	findPhotos(page, pageSize) {
		return this.api.post(`/photo/find?page=${page}&pageSize=${pageSize}`, { "sorts": { "id": -1 }}).then(res => res.data);
	}

	queryPhotos(page, pageSize) {
		return this.api.post(`/photo/query?page=${page}&pageSize=${pageSize}`, { }).then(res => res.data);
	}

	inboxPhotos(page, pageSize) {
		return this.api.post(`/photo/inbox?page=${page}&pageSize=${pageSize}`, { }).then(res => res.data);
	}

	reanalyzePhoto(photoId) {
		return this.api.get(`/photo/${photoId}/reanalyze`).then(res => res.data);
	}

	deletePhoto(photoId) {
		return this.api.delete(`/photo/${photoId}`).then(res => res.data);
	}

	rotateLeft(photoPk) {
		photoPk = photoPk.replace("#", "%23");
		return this.api.get(`/photo/${photoPk}/rotate/left`).then(res => res.data);
	}

	rotateRight(photoPk) {
		photoPk = photoPk.replace("#", "%23");
		return this.api.get(`/photo/${photoPk}/rotate/right`).then(res => res.data);
	}

	search(page, limit, searchOptions) {
		//console.log(searchOptions);
		return this.api.post(`/photo/search?limit=${limit}&page=${page}`, searchOptions).then(res => res.data);
	}

	reprocessExif(photoId) {
		return this.api.get(`/photo/${photoId}/reprocess/exif`).then(res => res.data);
	}

	reprocessLabels(photoId) {
		return this.api.get(`/photo/${photoId}/reprocess/labels`).then(res => res.data);
	}

	reprocessModerationLabels(photoId) {
		return this.api.get(`/photo/${photoId}/reprocess/moderationlabels`).then(res => res.data);
	}

	reprocessTexts(photoId) {
		return this.api.get(`/photo/${photoId}/reprocess/text`).then(res => res.data);
	}

	reprocessSizes(photoId) {
		return this.api.get(`/photo/${photoId}/reprocess/sizes`).then(res => res.data);
	}

}
