
import React from 'react';
import { Button } from 'primereact/button';
import { PhotoService } from '../../service/PhotoService';

export const ReprocessModerationLabelsButton = (props) => {

	const photoService = new PhotoService();

	const reprocessModerationLabels = () => {
		photoService.reprocessModerationLabels(props.photo.id).then(data => {
			//console.log(data);
		});
	}

	return (
		<Button label="Reprocess Moderation Labels" onClick={reprocessModerationLabels} ></Button>
	);
}
