import React, { useState, useEffect } from 'react';
import { MainWithFooter } from '../components/MainWithFooter';

import { PhotoService } from '../service/PhotoService';

export const BrowsePage = () => {

	const [selectedGroups, setSelectedGroups] = useState([]);
	const [selectedTexts, setSelectedTexts] = useState([]);
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [aggregationCount, setAggregationCount] = useState(1000);

	const [ searchOptions] = useState({"aggregations": [ "faces.group.name", "photoLabels.label.name", "photoTexts.textDetection" ]});

	const [ pagination, setPagination] = useState({ page: 0, limit: 12, totalResults: 0});

	const photoService = new PhotoService();

	useEffect(() => {

		if(false) {
			setAggregationCount(1000);
			setSelectedGroups([]);
			selectedTexts([]);
			setSelectedTexts([]);
			setSelectedLabels([]);
			setPagination({});

			let _searchOptions = {
				...searchOptions,
				aggregationCount: aggregationCount
			};

			if(selectedGroups) {
				let searchFilters = {};
				for(let group of selectedGroups) {
					searchFilters[group.group + "Filter"] = {};
					searchFilters[group.group + "Filter"]["faces.group.name"] = {
						queryString: group.group
					};
				}
				_searchOptions = {
					...searchOptions,
					searchFilters
				};
			}

			if(selectedLabels) {
				let searchFilters = {};
				for(let label of selectedLabels) {
					searchFilters[label.label + "Filter"] = {};
					searchFilters[label.label + "Filter"]["photoLabels.label.name"] = {
						queryString: label.label
					};
				}
				_searchOptions = {
					...searchOptions,
					searchFilters
				};
			}

			if(selectedTexts) {
				let searchFilters = {};
				for(let text of selectedTexts) {
					searchFilters[text.text + "Filter"] = {};
					searchFilters[text.text + "Filter"]["photoTexts.textDetection"] = {
						queryString: text.text
					};
				}
				_searchOptions = {
					...searchOptions,
					searchFilters
				};
			}

			console.log(_searchOptions);

			photoService.search(pagination.page, pagination.limit, _searchOptions).then(data => {
				console.log(data);
			});

			//console.log(_searchOptions);
		}

	}, [pagination.page, pagination.limit, aggregationCount]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<MainWithFooter>
			<div>Browse Page</div>
		</MainWithFooter>
	);
}
