import React, { useEffect } from "react";
import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from "react-router";

export const LoginPage = () => {
	const { user } = useAuthenticator(context => [context.user]);
	const location = useLocation();
	const navigate = useNavigate();
	let from = location.state?.from?.pathname || "/";

	useEffect(() => {
		console.log(user);
		if(user) {
			//localStorage.setItem("accessToken", user.signInUserSession.accessToken.jwtToken);
			localStorage.setItem("idToken", user.signInUserSession.idToken.jwtToken);
			console.log("User logged in");
			console.log(user);
			navigate(from, { replace: true});
		}
	}, [user]); // eslint-disable-line react-hooks/exhaustive-deps

	const formFields = {
		signIn: {
			username: {
				placeholder: 'Email:',
				isRequired: true,
			},
		},
		signUp: {
			username: {
				placeholder: 'Email:',
				isRequired: true,
			},
		},
	}

	return (
		<div className="grid">
			<div className="col-12" style={{ height: "100vh", width: "100vw", display: "grid" }}>
				<Authenticator formFields={formFields}></Authenticator>
			</div>
		</div>
  	)
}
