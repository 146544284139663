import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useAuthenticator } from "@aws-amplify/ui-react";

import { AppTopbar } from './AppTopbar';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import { RequireAuthToDisplay } from './components/RequireAuthToDisplay';
import { SummaryStatsService } from './service/SummaryStatsService';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import { useStickyState } from "./sitefunctions";

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

import './assets/layout/layout.scss';
import './assets/css/App.scss';

export const App = (props) => {

	const { user } = useAuthenticator((context) => [context.user]);

	const [layoutMode, setLayoutMode] = useState('static');
	const [layoutColorMode, setLayoutColorMode] = useStickyState('light', 'layoutColorMode');
	const [inputStyle, setInputStyle] = useState('outlined');
	const [ripple, setRipple] = useState(true);

	const [summaryStats, setSummaryStats] = useState({});

	const [staticMenuInactive, setStaticMenuInactive] = useState(false);
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [mobileMenuActive, setMobileMenuActive] = useState(false);
	const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
	const copyTooltipRef = useRef();

	const { children } = props;
	const location = useLocation();

	PrimeReact.ripple = true;

	let menuClick = false;
	let mobileTopbarMenuClick = false;

	useEffect(() => {
		if (mobileMenuActive) {
			addClass(document.body, "body-overflow-hidden");
		} else {
			removeClass(document.body, "body-overflow-hidden");
		}
	}, [mobileMenuActive]);

	useEffect(() => {
		if(user) {
			const summaryStatsService = new SummaryStatsService();
			summaryStatsService.stats().then(data => {
				//console.log(data);
				setSummaryStats(data.entity);
			});
		}

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setStaticMenuInactive(!user);
	}, [user]);

	useEffect(() => {
		copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
	}, [location]);

	const onInputStyleChange = (inputStyle) => {
		setInputStyle(inputStyle);
	}

	const onRipple = (e) => {
		PrimeReact.ripple = e.value;
		setRipple(e.value)
	}

	const onLayoutModeChange = (mode) => {
		setLayoutMode(mode)
	}

	const onColorModeChange = (mode) => {
		setLayoutColorMode(mode)
	}

	const onWrapperClick = (event) => {
		if (!menuClick) {
			setOverlayMenuActive(false);
			setMobileMenuActive(false);
		}

		if (!mobileTopbarMenuClick) {
			setMobileTopbarMenuActive(false);
		}

		mobileTopbarMenuClick = false;
		menuClick = false;
	}

	const onToggleMenuClick = (event) => {
		menuClick = true;

		console.log(event);

		if (isDesktop()) {
			if (layoutMode === 'overlay') {
				if (mobileMenuActive === true) {
					setOverlayMenuActive(true);
				}

				setOverlayMenuActive((prevState) => !prevState);
				setMobileMenuActive(false);
			}
			else if (layoutMode === 'static') {
				setStaticMenuInactive((prevState) => !prevState);
			}
		}
		else {
			setMobileMenuActive((prevState) => !prevState);
		}
		console.log(layoutMode);
		console.log(mobileMenuActive);
		console.log(overlayMenuActive);

		event.preventDefault();
	}

	const onSidebarClick = () => {
		menuClick = true;
	}

	const onMobileTopbarMenuClick = (event) => {
		mobileTopbarMenuClick = true;

		setMobileTopbarMenuActive((prevState) => !prevState);
		event.preventDefault();
	}

	const onMobileSubTopbarMenuClick = (event) => {
		mobileTopbarMenuClick = true;

		event.preventDefault();
	}

	const onMenuItemClick = (event) => {
		if (!event.item.items) {
			setOverlayMenuActive(false);
			setMobileMenuActive(false);
		}
	}
	const isDesktop = () => {
		return window.innerWidth >= 992;
	}

	const menu = [
		{
			label: 'Home', icon: 'pi pi-fw pi-home',
			items: [{
				label: 'Search', icon: 'pi pi-fw pi-home', to: '/search'
			}]
		}, {
			label: 'Photos',
			items: [{
				label: 'Inbox', icon: 'pi pi-fw pi-home', to: '/photosinbox'
			}, {
				label: 'Browse', icon: 'pi pi-fw pi-home', to: '/photosbrowse'
			}]
		}, {
			label: 'Faces', icon: 'pi pi-fw pi-sitemap',
			items: [
				{
					label: 'Face Groups' + (user && summaryStats && summaryStats.unNamedFaceGroups > 0 ? " (" + summaryStats.unNamedFaceGroups + ")" : ""), icon: 'pi pi-fw pi-id-card', to: '/facegroups'
				}, {
					label: 'Face Grouping', icon: 'pi pi-fw pi-home', to: '/facegrouping'
				}
			]
		}, {
			label: 'Categories', icon: 'pi pi-fw pi-sitemap',
			items: [
				{ label: 'Labels', icon: 'pi pi-fw pi-id-card', to: '/labels' },
				{ label: 'Moderation Labels', icon: 'pi pi-fw pi-id-card', to: '/moderationlabels' },
				{ label: 'Words', icon: 'pi pi-fw pi-id-card', to: '/words' },
			]
		}, {
			label: 'Profile', icon: 'pi pi-fw pi-sitemap',
			items: [
				{ label: 'Account', icon: 'pi pi-fw pi-id-card', to: '/profile' },
				{ label: 'Subscription', icon: 'pi pi-fw pi-id-card', to: '/subscription' },
			]
		}
	];

	const addClass = (element, className) => {
		if (element.classList)
			element.classList.add(className);
		else
			element.className += ' ' + className;
	}

	const removeClass = (element, className) => {
		if (element.classList)
			element.classList.remove(className);
		else
			element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}

	const wrapperClass = () => {
		let extraClass = '';
		if(!user) extraClass = 'landing-body';
		return (
			classNames(extraClass, 'layout-wrapper', 'landing-wrapper', {
				'layout-overlay': layoutMode === 'overlay',
				'layout-static': layoutMode === 'static',
				'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
				'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
				'layout-mobile-sidebar-active': mobileMenuActive,
				'p-input-filled': inputStyle === 'filled',
				'p-ripple-disabled': ripple === false,
				//'layout-theme-light': layoutColorMode === 'light'
			})
		);
	}

	return (
		<div className={wrapperClass()} onClick={onWrapperClick}>

			<Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

			<RequireAuthToDisplay>
				<AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
					mobileTopbarMenuActive={mobileTopbarMenuActive}
					onMobileTopbarMenuClick={onMobileTopbarMenuClick}
					onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
					logout={props.logoutHandler}
				/>
				<div className="layout-sidebar" onClick={onSidebarClick}>
					<AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
				</div>
			</RequireAuthToDisplay>

			{children}

			<RequireAuthToDisplay>
				<AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
					layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />
			</RequireAuthToDisplay>

			<CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
				<div className="layout-mask p-component-overlay"></div>
			</CSSTransition>
		</div>
	);
}
