import React from 'react';

import { useAuthenticator } from "@aws-amplify/ui-react";

import { HomePage } from './HomePage';
import { LandingPage } from './LandingPage';
import { RequireAuth } from '../components/RequireAuth';

export const MainPage = () => {

	const { user } = useAuthenticator(context => [context.user]);

	console.log(user);

	if(user) {
		console.log("MainPage");
		return (
			<RequireAuth><HomePage /></RequireAuth>
		);
	} else {
		return (
			<LandingPage />
		);
	}

}
