import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { useAuthenticator } from "@aws-amplify/ui-react";

//import { useAmplifyAuth } from './useAmplifyAuth';

import { App } from './App';

import { RequireSubscription } from './components/RequireSubscription';
import { RequireEmailValidation } from './components/RequireEmailValidation';

import { RequireAuth } from './components/RequireAuth';

import { LoginPage } from './pages/LoginPage';

import { BrowsePage } from './pages/BrowsePage';
import { LabelsPage } from './pages/LabelsPage';
import { ModerationLabelsPage } from './pages/ModerationLabelsPage';
import { WordsPage } from './pages/WordsPage';

import { PhotosInboxPage } from './pages/PhotosInboxPage';
import { FaceGroupsPage } from './pages/FaceGroupsPage';
import { FaceGroupingPage } from './pages/FaceGroupingPage';
import { Subscription } from './pages/Subscription';
import { SearchPage } from './pages/SearchPage';
import { MainPage } from './pages/MainPage';
import { WelcomePage } from './pages/WelcomePage';
import { ProfilePage } from './pages/ProfilePage';

export const AppSecure = () => {

	const { signOut } = useAuthenticator((context) => [context.signOut]);

	const navigate = useNavigate();

	const logoutHandler = () => {
		console.log("Logout getting called");
		localStorage.removeItem("idToken");
		navigate("/", { replace: true});
		signOut();
	};

	return (
		<RequireEmailValidation logoutHandler={logoutHandler}>
			<App logoutHandler={logoutHandler}>
				<Routes>
					<Route path="/" element={<MainPage />} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/labels" element={ <RequireAuth><RequireSubscription><LabelsPage /></RequireSubscription></RequireAuth> } />
					<Route path="/moderationlabels" element={<RequireAuth><RequireSubscription><ModerationLabelsPage /></RequireSubscription></RequireAuth>} />
					<Route path="/words" element={<RequireAuth><RequireSubscription><WordsPage /></RequireSubscription></RequireAuth>} />
					<Route path="/photosbrowse" element={<RequireAuth><RequireSubscription><BrowsePage /></RequireSubscription></RequireAuth>} />
					<Route path="/photosinbox" element={<RequireAuth><RequireSubscription><PhotosInboxPage /></RequireSubscription></RequireAuth>} />
					<Route path="/facegroups" element={<RequireAuth><RequireSubscription><FaceGroupsPage /></RequireSubscription></RequireAuth>} />
					<Route path="/facegrouping" element={<RequireAuth><RequireSubscription><FaceGroupingPage /></RequireSubscription></RequireAuth>} />
					<Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
					<Route path="/subscription" element={<RequireAuth><Subscription /></RequireAuth>} />
					<Route path="/search" element={<RequireAuth><RequireSubscription><SearchPage /></RequireSubscription></RequireAuth>} />
					<Route path="/welcome" element={<RequireAuth><RequireSubscription><WelcomePage /></RequireSubscription></RequireAuth>} />
				</Routes>
			</App>
		</RequireEmailValidation>

	);
}

