import React, { useState } from 'react';

import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

import { FullImageWithFaceBoxes } from '../components/FullImageWithFaceBoxes';

import { ReprocessExifButton } from '../components/buttons/ReprocessExifButton';
import { ReprocessLabelsButton } from '../components/buttons/ReprocessLabelsButton';
import { ReprocessModerationLabelsButton } from '../components/buttons/ReprocessModerationLabelsButton';
import { ReprocessTextsButton } from '../components/buttons/ReprocessTextsButton';
import { ReprocessFacesButton } from '../components/buttons/ReprocessFacesButton';
import { ReprocessSizesButton } from '../components/buttons/ReprocessSizesButton';

import { DeletePhotoButton } from '../components/buttons/DeletePhotoButton';

//import { FaceGroupService } from '../service/FaceGroupService';

export const PhotoDialog = (props) => {

	const [showFaces, setShowFaces] = useState(props.displayOptions.showFaces);
	const [showPhotoLabels, setShowPhotoLabels] = useState(props.displayOptions.showPhotoLabels);
	const [showPhotoModerationLabels, setShowPhotoModerationLabels] = useState(props.displayOptions.showPhotoModerationLabels);
	const [showPhotoTexts, setShowPhotoTexts] = useState(props.displayOptions.showPhotoTexts);
	const [showLandmarks, setShowLandmarks] = useState(props.displayOptions.showLandmarks);

	//const faceGroupService = new FaceGroupService();

	const faceTemplate = (faceRecord) => {
		//console.log(photoface);
		//console.log(faceRecord);

		return (
			<div>{faceRecord.face.group ? faceRecord.face.group.name : "Face_Id: " + faceRecord.face.faceId.substring(0,8)}</div>
		);
	}

	const labelTemplate = (label) => {
		//console.log(photolabel);
		//let label = JSON.parse(photolabel.label);
		//console.log(photolabel);
		return (
			<div>{label.name} ({Math.round(label.confidence * 10) / 10}%)</div>
		);
	}

	const moderationLabelTemplate = (moderationLabel) => {
		//console.log(photoModerationLabel);
		//let label = JSON.parse(photoModerationLabel.moderationLabel);
		//console.log(label);
		return (
			<div>{moderationLabel.name} ({Math.round(moderationLabel.confidence * 10) / 10}%)</div>
		);
	}

	const textTemplate = (textDetection) => {

		//console.log(textDetection);
		return (
			<div>{textDetection.detectedText} ({Math.round(textDetection.confidence * 10) / 10}%)</div>
		);
	}







	const totalPPU = () => {
		let sum = 0;
		for(let thing of props.selectedPhoto.photoProcessingEvents) {
			sum += thing.processingUnits;
		}
		return sum;
	}

	let footerGroup =
		<ColumnGroup>
			<Row>
				<Column footer="Total PPU:" footerStyle={{textAlign: 'right'}}/>
				<Column footer={totalPPU} />
			</Row>
		</ColumnGroup>;

	const EventsTable = () => {
		if(props.selectedPhoto.photoProcessingEvents) {
			return (
				<Card title="PPU Info" subTitle="Photo Processing Units">
					<DataTable value={props.selectedPhoto.photoProcessingEvents} footerColumnGroup={footerGroup}>
						<Column field="message" header="Processing Action"></Column>
						<Column field="processingUnits" header="PPU"></Column>
					</DataTable>
				</Card>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	return (
		<div>
			<Dialog style={{ width: '70vw' }} visible={props.showPhotoDialog} header="Photo Viewer" onHide={() => { props.setShowPhotoDialog(false); }}>
				<div className="grid">
					<div className="col-3">
						<Card style={{ display: props.selectedPhoto && props.selectedPhoto.faces ? "block" : "none"}}
							title={
								<div className="field-checkbox">
									<div className="grid">
										<div className="col-5">
											<Checkbox inputId="cb1" onChange={(e) => setShowFaces(e.checked)} checked={showFaces} />
											<label htmlFor="cb1" className="p-checkbox-label">Faces</label>
										</div>
										<div className="col-7">
											<Checkbox inputId="cb5" onChange={(e) => setShowLandmarks(e.checked)} checked={showLandmarks} />
											<label htmlFor="cb5" className="p-checkbox-label">Landmarks</label>
										</div>
									</div>
								</div>
							}
						>
							<ListBox listStyle={{ maxHeight: '160px' }} options={props.selectedPhoto ? props.selectedPhoto.faces : []} itemTemplate={faceTemplate} style={{ width: '100%' }} />
						</Card>
						<Card style={{ display: props.selectedPhoto && props.selectedPhoto.labels ? "block" : "none"}}
							title={
								<div className="field-checkbox">
									<Checkbox inputId="cb2" onChange={(e) => setShowPhotoLabels(e.checked)} checked={showPhotoLabels} />
									<label htmlFor="cb2" className="p-checkbox-label">Labels</label>
								</div>
							}
						>
							<ListBox listStyle={{ maxHeight: '160px' }} options={props.selectedPhoto ? props.selectedPhoto.labels : []} itemTemplate={labelTemplate} style={{ width: '100%' }} />
						</Card>
						<Card style={{ display: props.selectedPhoto && props.selectedPhoto.moderationLabels ? "block" : "none"}}
							title={
								<div className="field-checkbox">
									<Checkbox inputId="cb4" onChange={(e) => setShowPhotoModerationLabels(e.checked)} checked={showPhotoModerationLabels} />
									<label htmlFor="cb4" className="p-checkbox-label">Moderation Labels</label>
								</div>
							}
						>
							<ListBox listStyle={{ maxHeight: '160px' }} options={props.selectedPhoto ? props.selectedPhoto.moderationLabels : []} itemTemplate={moderationLabelTemplate} style={{ width: '100%' }} />
						</Card>
						<Card style={{ display: props.selectedPhoto && props.selectedPhoto.texts ? "block" : "none"}}
							title={
								<div className="field-checkbox">
									<Checkbox inputId="cb3" onChange={(e) => setShowPhotoTexts(e.checked)} checked={showPhotoTexts} />
									<label htmlFor="cb3" className="p-checkbox-label">Texts</label>
								</div>
							}
						>
							<ListBox listStyle={{ maxHeight: '160px' }} options={props.selectedPhoto ? props.selectedPhoto.texts : []} itemTemplate={textTemplate} style={{ width: '100%' }} />
						</Card>
					</div>
					<div className="col-9">
						<div className="grid">
							<div className="col-12">
								<Tooltip target=".leftIcon" mouseTrack mouseTrackLeft={10}/>
								<Tooltip target=".rightIcon" mouseTrack mouseTrackLeft={10}/>
								<FullImageWithFaceBoxes
									imageSize={"large"}
									key={props.selectedPhoto}
									photo={props.selectedPhoto}
									showLandmarks={showLandmarks}
									faces={props.selectedPhoto ? props.selectedPhoto.faces : []}
									labels={props.selectedPhoto ? props.selectedPhoto.labels : []}
									moderationLabels={props.selectedPhoto ? props.selectedPhoto.moderationLabels : []}
									texts={props.selectedPhoto ? props.selectedPhoto.texts : []}
									showFaces={showFaces}
									showPhotoLabels={showPhotoLabels}
									showPhotoModerationLabels={showPhotoModerationLabels}
									showPhotoTexts={showPhotoTexts}
									updateHandler={props.updateHandler}
									onClick={props.clickHandler}
									maxHeight="80vh"
								/>
							</div>
							<div className="col-6">
								<EventsTable />
							</div>
						</div>
					</div>
					<div className="col-3">
						<div className="grid">

							<div className="col-12">
								<ReprocessExifButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<ReprocessLabelsButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<ReprocessModerationLabelsButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<ReprocessTextsButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<ReprocessFacesButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<ReprocessSizesButton photo={props.selectedPhoto} />
							</div>
							<div className="col-12">
								<DeletePhotoButton updateHandler={props.updateHandler} photo={props.selectedPhoto} setShowPhotoDialog={props.setShowPhotoDialog}/>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);

}
