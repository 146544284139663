import React, { useState, useEffect } from 'react';

import { Paginator } from 'primereact/paginator';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FaceDialog } from '../components/FaceDialog';
import { Tooltip } from 'primereact/tooltip';

import { FaceGroupService } from '../service/FaceGroupService';
import { FullFaceWithRotation } from './FullFaceWithRotation';

import '../assets/css/EditFaceGroup.scss';


export const EditFaceGroup = (props) => {

	//const [activeIndex, setActiveIndex] = useState(0);
	const [total, setTotal] = useState(0);
	const [pageSize, setPageSize] = useState(12);
	//const [page, setPage] = useState(0);
	const [first, setFirst] = useState(0);

	const [loadedGroup, setLoadedGroup] = useState({});

	const [selectedFace, setSelectedFace] = useState({});
	const [showFaceDialog, setShowFaceDialog] = useState(false);

	const [groupName, setGroupName] = useState('');

	const [allFaces, setAllFaces] = useState([]);
	const [pagedFaces, setPagedFaces] = useState([]);
	const [updateFaceList, setUpdateFaceList] = useState(false);

	const [lastFaceGroup, setLastFaceGroup] = useState({});

	const faceGroupService = new FaceGroupService();

	useEffect(() => {

		//console.log("Get group " + props.faceGroup);
		//console.log(props.faceGroup);
		if(props.faceGroup) {

			faceGroupService.getFaceGroup(props.faceGroup.id).then(data => {
				setLoadedGroup(data.entity);
				setGroupName(data.entity.name);
				//console.log(data.entity);
				setAllFaces(data.entity.faces);
				//console.log(lastFaceGroup);
				//console.log(props.faceGroup);
				if(lastFaceGroup.id === props.faceGroup.id) {
					setPagedFaces(data.entity.faces.slice(first, first + pageSize));
				} else {
					setFirst(0);
					setPagedFaces(data.entity.faces.slice(0, pageSize));
				}
				setTotal(data.entity.faces.length);
				setLastFaceGroup(data.entity);
				//setActiveIndex(0);
				//console.log(data.entity.faces);
			});
		}

	}, [props.faceGroup, updateFaceList]); // eslint-disable-line react-hooks/exhaustive-deps

	const saveFaceGroup = async (event) => {
		//console.log(event.target.value);
		//console.log(props.faceGroup.name);
		//console.log(event.target.value);
		//console.log(event.target);
		//console.log(groupName);
		//groupName = event.target.value;

		//props.faceGroup.name = event.target.value;
		//loadedFaceGroup.name = event.target.value;
		//props.faceGroup.name = event.target.value;

		faceGroupService.saveFaceGroup({id: loadedGroup.id, name: groupName}).then(data => {
			//console.log(data);
			//setGroupName(event.target.value);
			props.triggerGroupListUpdate();
		});
		//setSelectedFaceGroupName(event.target.value);
		//console.log(props.faceGroup);
	}

	const changeFaceGroup = (event) => {
		setGroupName(event.target.value);
	}


	//	if(selectedFaceGroups) {
			//var result = selectedFaceGroups.map(i => i.id);
	//		console.log(result);

			//setLoadedFaces([]);
			//setLoadedFaceGroups([]);

			//for(let id of result) {

			//}


	//	}


	const deleteFaceGroup = async () => {
		console.log(props.selectedFaceGroups);

		faceGroupService.deleteFaceGroup(props.faceGroup.id).then(data => {
			//console.log(data);
			props.triggerGroupListUpdate();
		});
	}


	const rejectDelete = () => {
		console.log(props);
	}

	const confirmDeleteFaceGroup = () => {
		confirmDialog({
			message: 'Are you sure you want to delete FaceGroup: ' + props.faceGroup.name,
			header: 'Delete FaceGroup',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deleteFaceGroup,
			reject: rejectDelete
		});
	};

	const clickHandler = (face) => {
		setSelectedFace(face);
		setShowFaceDialog(true);
	}

	const updateHandler = () => {
		//console.log("updateHandler");
		setUpdateFaceList(!updateFaceList);
	}

	const itemTemplate = (face) => {
		return (
			<FullFaceWithRotation
				photo={face.photo}
				face={face}
				updateHandler={updateHandler}
				onClick={clickHandler}
			/>
		);
	}

	const onPaginatorChange = (event) => {
		//console.log(event);

		setFirst(event.first);
        //setPage(event.page);
        setPageSize(event.rows);

        setPagedFaces(allFaces.slice(event.first, event.first + pageSize));
    }

	const imagesTemplate = (faces) => {
		let ret = [];
		for(let face of faces) {
			//console.log(photo.faces);
			ret.push(
				<div key={face.id} className="col-6 xl:col-3">
					{itemTemplate(face)}
				</div>
			);
		}
		return ret;
	}

	return (
		<>
			<div className="col-12"></div>
			<div className="col-12"></div>
			<div className="col-12">
				<div className="grid">
					<div className="col-5">
						<span className="p-float-label">
							<InputText name="inputtext" value={groupName} style={{ width: "100%"}} onChange={changeFaceGroup} />
							<label htmlFor="inputtext">Face Group Name</label>
						</span>
					</div>
					<div className="col-1">
						<Button style={{ display: loadedGroup && loadedGroup.faces ? "block" : "none"}} label="Save" onClick={saveFaceGroup} />
					</div>

					<div className="col-6">
						<div className="flex justify-content-end">
							<Button label="Delete FaceGroup" onClick={confirmDeleteFaceGroup} icon="pi pi-times" ></Button>
						</div>
					</div>

				</div>
			</div>
			<div className="col-12">
				<Tooltip target=".leftIcon" mouseTrack mouseTrackLeft={10}/>
				<Tooltip target=".rightIcon" mouseTrack mouseTrackLeft={10}/>
				<Paginator first={first} rows={pageSize} totalRecords={total} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={onPaginatorChange} />
				<div className="grid" id="photos">
					{imagesTemplate(pagedFaces)}
				</div>
				<Paginator first={first} rows={pageSize} totalRecords={total} rowsPerPageOptions={[2, 12, 18, 24, 48, 96, 192]} onPageChange={onPaginatorChange} />


				{/*<Galleria
					value={allFaces}
					responsiveOptions={responsiveOptions}
					numVisible={6}
					style={{ maxWidth: '100%', backgroundColor: "black", paddingTop: "10px", paddingBottom: "10px", marginBottom: "10px" }}
					circular
					showItemNavigators showThumbnails={false}
					activeIndex={activeIndex}
					onItemChange={(e) => setActiveIndex(e.index)}
					item={itemTemplate}
				/>
				{activeIndex + 1} of {allFaces ? allFaces.length : 0}*/}
			</div>
			<FaceDialog
				triggerGroupListUpdate={props.triggerGroupListUpdate}
				loadedGroup={loadedGroup}
				showFaceDialog={showFaceDialog}
				setShowFaceDialog={setShowFaceDialog}
				updateHandler={updateHandler}
				selectedPhoto={selectedFace.photo}
				selectedFace={selectedFace}
			/>
		</>
	);


}
