import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { useAuthenticator } from "@aws-amplify/ui-react";

export const RequireAuth = ({ children }) => {

	const { user } = useAuthenticator(context => [context.user]);

	const location = useLocation();

	if(user) {
		console.log("User is authenticated");
		return children;
	} else {
		console.log("User not authenticated");
		return (<Navigate to="/login" state={{ from: location }} replace />);
	}
}
