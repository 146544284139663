import React from 'react';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

import { FullImageWithFaceBoxes } from '../components/FullImageWithFaceBoxes';

import { FaceGroupService } from '../service/FaceGroupService';
import { PhotoService } from '../service/PhotoService';

export const FaceDialog = (props) => {

	const faceGroupService = new FaceGroupService();
	const photoService = new PhotoService();

	const clickHandler = () => {
		if(props.clickHandler) {
			props.clickHandler();
		}
	}

	const updateHandler = () => {
		if(props.updateHandler) {
			props.updateHandler();
		}
	}

	const removeFaceFromGroup = async (event) => {
		if(props.loadedGroup) {

			faceGroupService.deleteFaceFromGroup(props.selectedFace.id).then(data => {
				//console.log(event);
				//console.log(allFaces[activeIndex]);
				props.setShowFaceDialog(false);
				props.triggerGroupListUpdate();
			});
		}
	}

	const deletePhoto = async () => {
		//console.log(loadedGroup.faces[activeIndex].photo.id);
		photoService.deletePhoto(props.selectedPhoto.id).then(data => {
			//console.log(data);
			props.setShowFaceDialog(false);
			props.triggerGroupListUpdate();
		});
	}

	const rejectDelete = () => {
		console.log(props);
	}

	const confirmRemoveFaceFromGroup = () => {
		confirmDialog({
			message: 'Are you sure you want to remove this face from FaceGroup: ' + props.loadedGroup.name,
			header: 'Remove Face from FaceGroup',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: removeFaceFromGroup,
			reject: rejectDelete
		});
	};

	const confirmDeletePhoto = () => {
		confirmDialog({
			message: 'Are you sure you want to delete this photo?',
			header: 'Delete Photo',
			icon: 'pi pi-info-circle',
			acceptClassName: 'p-button-danger',
			accept: deletePhoto,
			reject: rejectDelete
		});
	};

	return (
		<div>
			<Dialog style={{ width: '70vw' }} visible={props.showFaceDialog} header="Face Viewer" onHide={() => { props.setShowFaceDialog(false); }}>
				<div className="grid">
					<div className="col-3">
						<div className="grid">
							<div className="col-12">
								<Button label="Remove Face from FaceGroup" onClick={confirmRemoveFaceFromGroup} icon="pi pi-times" />
							</div>
							<div className="col-12">
								<Button label="Delete Photo" onClick={confirmDeletePhoto} icon="pi pi-times" ></Button>
							</div>
						</div>
					</div>
					<div className="col-9">
						<Tooltip target=".leftIcon" mouseTrack mouseTrackLeft={10}/>
						<Tooltip target=".rightIcon" mouseTrack mouseTrackLeft={10}/>
						<FullImageWithFaceBoxes
							imageSize={"large"}
							key={props.selectedPhoto}
							photo={props.selectedPhoto}
							showLandmarks={false}
							faces={[props.selectedFace]}
							//photoLabels={props.selectedFace.photo ? props.selectedFace.photo.photoLabels : []}
							//photoModerationLabels={props.selectedFace.photo ? props.selectedFace.photo.photoModerationLabels : []}
							//photoTexts={props.selectedFace.photo ? props.selectedFace.photo.photoTexts : []}
							showFaces={true}
							updateHandler={updateHandler}
							onClick={clickHandler}
							maxHeight="80vh" />
					</div>
				</div>
			</Dialog>
		</div>
	);

}
