import React, {useRef, useState, useEffect } from 'react';

import { Toast } from 'primereact/toast';
import { PhotoService } from '../service/PhotoService';
import { MainWithFooter } from '../components/MainWithFooter';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from 'primereact/dropdown';
import { PhotoResults } from '../components/PhotoResults';

export const WordsPage = () => {

	const toast = useRef(null);
	const [selectedTexts, setSelectedTexts] = useState(null);

	const [updateTexts, setUpdateTexts] = useState(false);

	const [aggregationCount, setAggregationCount] = useState(250);

	const [ pagination, setPagination] = useState({ page: 0, limit: 12, totalResults: 0});

	const [textResults, setTextResults] = useState([]);

	const [ displayOptions] = useState({
		showFaces: false,
		showPhotoLabels: false,
		showPhotoModerationLabels: false,
		showPhotoTexts: true,
	});

	const [photos, setPhotos] = useState([]);

	const photoService = new PhotoService();

	const [ searchOptions] = useState({"aggregations": [ "photoTexts.textDetection" ]});

	const onPaginatorChange = (event) => {
		//console.log(event);
		let _pagination = {
			...pagination,
			limit: event.rows,
			page: event.page,
			first: event.first
		};
		setPagination(_pagination);
	}

	const itemTemplate = (rowData) => {
		//console.log(rowData);
		return (
			<div>
				{rowData.text} {"(" + rowData.count + ")"}
			</div>
		);
	}

	const removeSelectedText = (e, text) => {
		let _selectedTexts = [ ...selectedTexts ];
		_selectedTexts = _selectedTexts.filter(data => data.text !== text.text);
		setSelectedTexts(_selectedTexts);
		setUpdateTexts(!updateTexts);
	}

	const showSelectedItems = (props) => {
		if(selectedTexts) {
			let list = [];

			for(let text of selectedTexts) {
				//console.log(text);
				list.push(
					<Button key={text.text} label={text.text} onClick={(e) => {removeSelectedText(e, text)}} icon="pi pi-times" className="p-button-rounded" />
				);
			}
			return list;
		}
	}

	useEffect(() => {

		let _searchOptions = {
			...searchOptions,
			aggregationCount: aggregationCount
		};

		//console.log(_searchOptions);

		if(selectedTexts) {
			let searchFilters = {};
			for(let text of selectedTexts) {
				searchFilters[text.text + "Filter"] = {};
				searchFilters[text.text + "Filter"]["photoTexts.textDetection"] = {
					queryString: text.text
				};
			}
			_searchOptions.searchFilters = searchFilters;
		}

		photoService.search(pagination.page, pagination.limit, _searchOptions).then(data => {

			const newSelectedTexts = [];
			//console.log(data.aggregations["photoLabels.label.name"]);
			const newTextResults = [];

			if(data.aggregations) {
				for(let text in data.aggregations["photoTexts.textDetection"]) {

					let newText = {
						text: text,
						count: data.aggregations["photoTexts.textDetection"][text]
					};

					newTextResults.push(newText);

					if(data.totalResults > 0) {
						setPhotos(data.results);
					} else {
						setPhotos([]);
					}

					//console.log(photos);

					if(selectedTexts) {
						for(let oldText of selectedTexts) {
							if(oldText.text === newText.text) {
								newSelectedTexts.push(newText);
							}
						}
					}
				}
			}

			//console.log(newSelectedTexts);
			setSelectedTexts(newSelectedTexts);

			let _pagination = {
				...pagination,
				totalResults: data.totalResults
			};

			setPagination(_pagination);

			//console.log(newTextResults);
			newTextResults.sort((a, b) => { return b.count - a.count });

			setTextResults(newTextResults);

		});

	}, [pagination.page, pagination.limit, updateTexts, aggregationCount]); // eslint-disable-line react-hooks/exhaustive-deps


	return (
		<MainWithFooter>
			<div className="grid">
				<Toast ref={toast}></Toast>
				<div className="col-12 lg:col-4 xl:col-3">
					<Card title="Words / Texts">
						<ListBox
							value={selectedTexts}
							options={textResults}
							onChange={(e) => {
								setSelectedTexts(e.value);
								setUpdateTexts(!updateTexts);
								//console.log(e.value);
							}}
							multiple
							metaKeySelection={true}
							optionLabel="text"
							itemTemplate={itemTemplate}
							style={{ width: '100%' }}
							listStyle={{ maxHeight: '200px' }}
							filter
						/>
						<Dropdown value={aggregationCount} options={[10, 20, 25, 50, 100, 250, 500, 1000, 2000, 5000, 10000]} onChange={(e) => {
							setAggregationCount(e.value);
						}} />
					</Card>
				</div>
				<div className="col-12 lg:col-8 xl:col-9">
					<div className="grid">
						{ selectedTexts && selectedTexts.length > 0 &&
							<PhotoResults
								title="Words and Text in Photos"
								showSelectedItems={showSelectedItems}
								onPaginatorChange={onPaginatorChange}
								pagination={pagination}
								displayOptions={displayOptions}
								photos={photos}
								showCheckboxes={false}
								updateHandler={() => setUpdateTexts(!updateTexts)}
							/>
						}
					</div>
				</div>

			</div>
		</MainWithFooter>
	);
}
