import React from 'react';

import { Button } from 'primereact/button';


export const ProductDescription = (props) => {

	let productOptions = {};

	if(props.products) {

		let processingProduct = props.products["Photo Processing Per API Call"];
		let storageProduct = props.products["Photo Storage Per GB"];

		let features = [];

		let count = 0;
		for(let feature of props.features) {
			features.push(
				<li key={count} className="flex align-items-center mb-3">
					<i className="pi pi-check-circle text-green-500 mr-2"></i>
					<span>{feature}</span>
				</li>
			);
			count += 1;
		}

		productOptions[processingProduct.prices[props.billingType].id] = props.processing[props.billingType];
		productOptions[storageProduct.prices[props.billingType].id] = props.storage;

		let price = Number((processingProduct.prices[props.billingType].unitAmountDecimal * props.processing[props.billingType] / 100) + (storageProduct.prices[props.billingType].unitAmountDecimal * props.storage / 100)).toFixed(2);

		return (
			<div className="col-12 md:col-6 lg:col-6 xl:col-6">
				<div className="p-3 h-full">
					<div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
						<div className="text-900 font-medium text-xl mb-2">{props.name}</div>
						<hr className="my-3 mx-0 border-top-1 border-none surface-border" />
						<div className="flex align-items-center">
							<span className="font-bold text-2xl text-900">${price}</span>
							<span className="ml-2 font-medium text-600">per {props.billingType}</span>
						</div>
						<hr className="my-3 mx-0 border-top-1 border-none surface-border" />
						<ul className="list-none p-0 m-0 flex-grow-1">
							<li className="flex align-items-center mb-3">
								<i className="pi pi-check-circle text-green-500 mr-2"></i>
								<span>{props.storage}GB storage</span>
							</li>
							<li className="flex align-items-center mb-3">
								<i className="pi pi-check-circle text-green-500 mr-2"></i>
								<span>{props.processing[props.billingType].toLocaleString(undefined, { maximumFractionDigits: 2 })}PUs / {props.billingType} <span className="p-button-icon p-c p-button-icon-left pi pi-question-circle" onClick={props.showPUDialog} /></span>
							</li>
							{ features }
						</ul>
						<hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
						<Button label="Subscribe Now" className="p-3 w-full p-button-outlined" onClick={() => props.handleOnSubscribe(productOptions)} ></Button>
					</div>
				</div>
			</div>
		);
	} else {
		return (<></>);
	}

}
