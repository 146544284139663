import React, { useRef, useState, useEffect } from 'react';

//import { useQuery } from 'react-query';
import { Toast } from 'primereact/toast';
import { PhotoService } from '../service/PhotoService';
import { MainWithFooter } from '../components/MainWithFooter';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from 'primereact/dropdown';

import { PhotoResults } from '../components/PhotoResults';

export const LabelsPage = () => {

	const toast = useRef(null);
	const [selectedLabels, setSelectedLabels] = useState([]);

	const [updateLabels, setUpdateLabels] = useState(false);

	const [labelResults, setLabelResults] = useState([]);

	const [aggregationCount, setAggregationCount] = useState(25);

	const [ pagination, setPagination] = useState({ page: 0, limit: 12, totalResults: 0});

	const [ displayOptions] = useState({
		showFaces: false,
		showPhotoLabels: true,
		showPhotoModerationLabels: false,
		showPhotoTexts: false,
	});

	const [photos, setPhotos] = useState([]);

	const photoService = new PhotoService();

	const [ searchOptions] = useState({"aggregations": [ "photoLabels.label.name" ]});

	const onPaginatorChange = (event) => {
		//console.log(event);
		let _pagination = {
			...pagination,
			limit: event.rows,
			page: event.page,
			first: event.first
		};
		setPagination(_pagination);
	}

	useEffect(() => {

		//console.log(selectedLabels);

		let _searchOptions = {
			...searchOptions,
			aggregationCount: aggregationCount
		};

		//console.log(selectedLabels);

		if(selectedLabels) {
			let searchFilters = {};
			for(let label of selectedLabels) {
				searchFilters[label.label + "Filter"] = {};
				searchFilters[label.label + "Filter"]["photoLabels.label.name"] = {
					queryString: label.label
				};
			}
			_searchOptions.searchFilters = searchFilters;
		}

		photoService.search(pagination.page, pagination.limit, _searchOptions).then(data => {

			const newSelectedLabels = [];
			//console.log(data.aggregations["photoLabels.label.name"]);
			const newLabelResults = [];
			if(data.aggregations) {
				for(let label in data.aggregations["photoLabels.label.name"]) {
					//console.log(data.aggregations["photoLabels.label.name"][label]);
					//console.log(count);

					let newLabel = {
						label: label,
						count: data.aggregations["photoLabels.label.name"][label]
					};

					newLabelResults.push(newLabel);
					if(data.totalResults > 0) {
						setPhotos(data.results);
					} else {
						setPhotos([]);
					}

					if(selectedLabels) {
						for(let oldLabel of selectedLabels) {
							if(oldLabel.label === newLabel.label) {
								newSelectedLabels.push(newLabel);
							}
						}
					}

				}
			}
			//console.log(newSelectedLabels);
			setSelectedLabels(newSelectedLabels);

			let _pagination = {
				...pagination,
				totalResults: data.totalResults
			};

			setPagination(_pagination);

			setLabelResults(newLabelResults);
		});
	}, [pagination.page, pagination.limit, updateLabels, aggregationCount]); // eslint-disable-line react-hooks/exhaustive-deps

	const itemTemplate = (rowData) => {
		//console.log(rowData);
		return (
			<div>
				{rowData.label} {"(" + rowData.count + ")"}
			</div>
		);
	}

	const removeSelectedLabel = (e, label) => {
		let _selectedLabels = [ ...selectedLabels ];
		_selectedLabels = _selectedLabels.filter(data => data.label !== label.label);
		setSelectedLabels(_selectedLabels);
		setUpdateLabels(!updateLabels);
	}

	const showSelectedItems = (props) => {
		if(selectedLabels) {
			let list = [];

			for(let label of selectedLabels) {
				list.push(
					<Button key={label.label} label={label.label} onClick={(e) => {removeSelectedLabel(e, label)}} icon="pi pi-times" className="p-button-rounded" />
				);
			}
			return list;
		}
	}


	return (
		<MainWithFooter>
			<div className="grid">
				<Toast ref={toast}></Toast>
				<div className="col-12 lg:col-4 xl:col-3">
					<Card title="Labels">
						<ListBox
							value={selectedLabels}
							options={labelResults}
							onChange={(e) => {
								//console.log(e.value);
								setSelectedLabels(e.value);
								setUpdateLabels(!updateLabels);
							}}
							multiple
							metaKeySelection={true}
							optionLabel="label"
							itemTemplate={itemTemplate}
							style={{ width: '100%' }}
							listStyle={{ maxHeight: '200px' }}
							filter
						/>
						<Dropdown value={aggregationCount} options={[10, 20, 25, 50, 100, 250, 500, 1000, 2000, 5000, 10000]} onChange={(e) => {
							setAggregationCount(e.value);
						}} />
					</Card>
				</div>
				<div className="col-12 lg:col-8 xl:col-9">
					<div className="grid">
						{ selectedLabels && selectedLabels.length > 0 &&
							<PhotoResults
								title="Labeled Photos"
								showSelectedItems={showSelectedItems}
								onPaginatorChange={onPaginatorChange}
								pagination={pagination}
								displayOptions={displayOptions}
								photos={photos}
								showCheckboxes={false}
								updateHandler={() => setUpdateLabels(!updateLabels)}
							/>
						}
					</div>
				</div>

			</div>
		</MainWithFooter>
	);
}
