import { BaseAuthService } from './BaseAuthService';

export class StripeService extends BaseAuthService {

	getPrices() {
		return this.api.get(`/stripe/prices`).then(res => res.data);
	}

	getCheckoutSession(membership) {
		return this.api.post(`/stripe/create-checkout-session`, membership).then(res => res.data);
	}
}
