
import React from 'react';
import { Button } from 'primereact/button';
import { PhotoService } from '../../service/PhotoService';

export const ReprocessTextsButton = (props) => {

	const photoService = new PhotoService();

	const reprocessTexts = () => {
		photoService.reprocessTexts(props.photo.id).then(data => {
			//console.log(data);
		});
	}

	return (
		<Button label="Reprocess Texts" onClick={reprocessTexts}></Button>
	);
}
