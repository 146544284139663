
import React from 'react';
import { Button } from 'primereact/button';
import { PhotoService } from '../../service/PhotoService';

export const ReprocessFacesButton = (props) => {

	const photoService = new PhotoService();

	const reprocessFaces = () => {
		photoService.reprocessFaces(props.photo.id).then(data => {
			//console.log(data);
		});
	}

	return (
		<Button label="Reprocess Faces" onClick={reprocessFaces}></Button>
	);
}
