import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { AppFooter } from '../AppFooter';

export const EmailVerificationRequired = (props) => {

	const footer = <span>
		<Button label="Logout" icon="pi pi-check" style={{marginRight: '.25em'}} onClick={() => props.logoutHandler()}/>
	</span>;

	useEffect(() => {
		//console.log("Does this happen?");
		window.gtag('event', 'conversion', {
			'send_to': 'AW-979120804/reGKCLeg6r0DEKTl8NID',
			'value': 0.50,
			'currency': 'USD'
		 });
	}, []);

	return (
		<div className="layout-wrapper layout-static layout-static-sidebar-inactive">
			<div className="layout-topbar">

			</div>
			<div className="layout-main-container">
				<div className="layout-main">
					<div className="grid">
						<div className="col-6 col-offset-3">
							<Card title="Email Verification Required" footer={footer}>
								Please check your email for a email message from FaceGroup.io. Also check the Spam folder as it might have ended up there.
							</Card>
						</div>
					</div>
				</div>
				<AppFooter layoutColorMode={"light"} />
			</div>
		</div>
	);
}
