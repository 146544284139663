import React, { useEffect } from 'react';
import { MainWithFooter } from '../components/MainWithFooter';

export const HomePage = () => {

	useEffect(() => {
		window.gtag('event', 'conversion', {
			'send_to': 'AW-979120804/reGKCLeg6r0DEKTl8NID',
			'value': 0.50,
			'currency': 'USD'
		 });
	}, []);

	console.log("HomePage");

	return (
		<MainWithFooter>
			<div border="1px solid red">Home Page</div>
		</MainWithFooter>
	);
}
