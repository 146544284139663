import { BaseAuthService } from './BaseAuthService';

export class LocalUserService extends BaseAuthService {

	getLocalUser() {
		return this.api.get(`/localuser`).then(res => res.data);
	}

	saveSettings(settings) {
		return this.api.post(`/localuser/settings`, settings).then(res => res.data);
	}
}
