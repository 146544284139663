import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppSecure } from './AppSecure';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools} from 'react-query/devtools';
import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom'
//import { AmplifyAuthProvider } from './useAmplifyAuth';

import API from '@aws-amplify/api';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from '@aws-amplify/auth';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);
API.configure(awsconfig);

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Authenticator.Provider>
		<HashRouter>
			<QueryClientProvider client={queryClient}>
				<AppSecure />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</HashRouter>
	</Authenticator.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
