
import React from 'react';

import { PhotoService } from '../service/PhotoService';

import '../assets/css/FullImageWithFaceBoxes.scss';


export const FullFaceWithRotation = (props) => {

	const photoService = new PhotoService();

	let faceRecord = JSON.parse(props.face.facerecord);

	let top = faceRecord.face.boundingBox.top * 100;
	let left = faceRecord.face.boundingBox.left * 100;
	let height = faceRecord.face.boundingBox.height * 100;
	let width = faceRecord.face.boundingBox.width * 100;

	let xt = ((50 - left) - (width / 2));
	let yt = ((50 - top) - (height / 2));

	let sc = 100 / Math.max(height, width);

	//console.log(faceRecord.faceDetail.boundingBox);

	let style = {
		transform: `scale(${sc}) translate(${xt}%, ${yt}%)`,
		width: "100%"
		//maxHeight: "90vh", maxWidth: "90%"

	}

	const onClickHandler = (face) => {
		if(props.onClick) {
			props.onClick(face);
		}
	}

	const rotateLeft = (e) => {
		e.stopPropagation();
		photoService.rotateLeft(props.photo.id).then(data => {
			props.photo.rotation = data.entity.rotation;
			if(props.updateHandler) {
				props.updateHandler();
			}
		});
	}

	const rotateRight = (e) => {
		e.stopPropagation();
		photoService.rotateRight(props.photo.id).then(data => {
			props.photo.rotation = data.entity.rotation;
			if(props.updateHandler) {
				props.updateHandler();
			}
		});
	}

	if(props.face.photo) {
		//console.log(face.photo.rotation);

		if(props.face.photo.rotation === 90 || props.face.photo.rotation === 270) {
			return (
				<div style={{ textAlign: "center" }} onClick={() => { onClickHandler(props.face); }}>
					<div style={{ display: "table" }}>
						<div style={{ padding: "50% 0", height: 0 }}>
							<div className={"rotateFace" + props.face.photo.rotation} style={{ position: "relative", display: "inline-block", justifyContent: "center", overflow: "hidden" }}>
								<i className="rotate leftIcon pi pi-arrow-down-left" data-pr-tooltip="Rotate Left" onClick={rotateLeft} style={{ zIndex: 1 }} />
								<i className="rotate rightIcon pi pi-arrow-down-right" data-pr-tooltip="Rotate Right" onClick={rotateRight} style={{ zIndex: 1 }} />
								<img
									src={`https://photos.facegroup.io/${props.face.photo.s3PathLarge ? props.face.photo.s3PathLarge : props.face.photo.s3PathOriginal}`}
									alt={props.face.photo.originalFileName}
									style={style} />
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }} onClick={() => { onClickHandler(props.face); }}>
					<div className={"rotateFace" + props.face.photo.rotation} style={{ position: "relative", display: "inline-block", justifyContent: "center", overflow: "hidden" }}>
						<i className="rotate leftIcon pi pi-arrow-down-left" data-pr-tooltip="Rotate Left" onClick={rotateLeft} style={{ zIndex: 1 }} />
						<i className="rotate rightIcon pi pi-arrow-down-right" data-pr-tooltip="Rotate Right" onClick={rotateRight} style={{ zIndex: 1 }} />
						<img
							src={`https://photos.facegroup.io/${props.face.photo.s3PathLarge ? props.face.photo.s3PathLarge : props.face.photo.s3PathOriginal}`}
							alt={props.face.photo.originalFileName}
							style={style} />
					</div>
				</div>
			);
		}

	}

}
